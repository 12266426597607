import React, {Fragment} from "react";
import Nav from "../Partials/Nav";
import LeftSide from "../Partials/LeftSide";
import Home from "../Home";
import RightSide from "../Partials/RightSide";
import MobileNav from "../Partials/MobileNav";
import FriendProfile from "./FriendProfile";

export default function MainSectionProfile() {
    return(
        <>
            <div className="row d-flex justify-content-center">
                <div className="col-md-10">
            <FriendProfile/>
                </div>
            </div>
        </>
    )
}