import SlideStory from "./Story/SlideStory";
import React, {Fragment, useEffect, useState, useCallback,useRef} from "react";
import API from "../Network/API";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Post from "../Props/Post";
import CreatePost from "./CreatePost";
import DivPlaceholder from "../Network/DivPlaceholder";
import Echo from "laravel-echo";
import PostView from "./Modal/PostView";
import { useDispatch, useSelector } from "react-redux";
import Agent from "../Network/Agent";
import { POST_FETCH_ERROR, POST_FETCH_SUCCESS,POST_FETCH_SUCCESS_LOAD,POST_FETCH_COMMENT_ADDON } from "../Reducer/postReducer";
toast.configure();


 const Home =()=> {
    
    const [demo_data, setDatas] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}]);
    const [posts, setPosts] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [number_post, setNumberPost] = useState(0);
    const [progress, setProgress] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(1);
    const [next_page, setNextPage] = useState('/post_syncs');
    const [nextPageUrl, setNextPageURL] = useState('');
    const [lengthData, setDataLength] = useState(0);
    const observerElem = useRef(null)
    const myArr = JSON.parse(localStorage.getItem('user_data'));
    const dispatch = useDispatch();
    
    useEffect(() => {
        Agent.Articles.all(1,dispatch);
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
        
    }, []);

//with smooth-scroll

    // useEffect(() => {
      
    //     const echo = new Echo({
    //         broadcaster: 'pusher',
    //         key: 'key56556563562563624376',
    //         wsHost: 'b.connectsweb.com',
    //         wssHost: 'b.connectsweb.com',
    //         wsPort: 6001,
    //         wssPort: 443,
    //         forceTLS: true,
    //         encrypted: true,
    //         disableStats: true,
    //     });
    //     echo
    //         .channel(`App.PostComment.${myArr.id}`)
    //         .subscribed(() => {
    //             console.log('You are subscribed'+myArr.id);
    //         }).listen('PostCommentEvent', (value) => {
    //             console.log(value.data);
    //         });

    // }, []);


    function fetchRepositoriesNext() {

        setLoading(true)
        API.get(`/post_syncs?page=${page+1}`)
        .then(response => {
            setPage((page+1))
            setLoading(false)
            dispatch(POST_FETCH_SUCCESS_LOAD(response.data.data));
        }).catch((error) => {
            dispatch(POST_FETCH_ERROR());
            setLoading(false)
        })

    }

    const selector = useSelector((state) => state.postReducer)
    const postsData=selector?.post_data

    
    
    // dispatch(POST_FETCH_COMMENT_ADDON(payload));
    // const test=postsData.filter(post=> post.id === 1397)[0]?.last_comments.concat(payload);

  


    return (
        <Fragment>
            <div className="row d-flex justify-content-center">
                <div className="col-md-10">
            <div className="story-postbox news-feed-area">
                <SlideStory/>
                <div className="row justify-content-md-center">
                    <div className="col-md-10">

                        <CreatePost/>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-10">
                        {selector?.loading && demo_data?.map((post, i) => (
                            <DivPlaceholder key={i} backgroundColor="#ffffff"
                                            foregroundColor="#ecebeb"/>
                        ))}

                        {!selector?.loading && postsData?.map((post, index) => (
                            <Post key={index} data={post} />
                        ))}

                        {/* <div className="news-feed news-feed-post bg-white shadow-sm mb-3 ">
                            <ins className="adsbygoogle"
                                style={{ display: 'block' }}
                                data-ad-format="fluid"
                                data-ad-layout-key="-62+cf+2x-2a+91"
                                data-ad-client="ca-pub-2988488134974733"
                                data-ad-slot="6417125186"></ins>
                        </div> */}

                        <div className='loader' ></div>

                        { selector?.data.next_page_url!=null?
                                <div className={'text-center'} style={{marginBottom:'10px'}}>

                                <button  className={'btn btn-sm btn-primary'} onClick={fetchRepositoriesNext}>Load More {isLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-5"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    <span></span>
                                )}</button>
                                </div>
                                :''
                        }


                    </div>
                </div>
            </div>
                </div>
                </div>
                </Fragment>
    )
}
export default Home;