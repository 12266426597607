import ContentLoader from "react-content-loader";
export default function PlaceholderSuggestFriend() {
    return (
        <>

            <ContentLoader
                speed={1}
                height={100}
                viewBox="0 0 380 70"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="100" ry="100" width="40" height="40" />
                <rect x="50" y="17" rx="4" ry="4" width="100" height="13" />

            </ContentLoader>

        </>
    );
}