import React, {useState} from "react";
import API from "../../Network/API";
import {toast} from "react-toastify";

export default function MessageAction({value}){
    const [isLoading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem("user_data"));
    const deleteMessageEveryOne = (messageId) =>{
        // console.log(messageId)
        var data = {
            'message_id': messageId,
        }
        API.post(`conversations/${messageId}/delete/everyone`, data).then(response => {
            setLoading(false);
            toast.success('Message delete success');
        }).catch((error) => {
            setLoading(false);
        });
    }

    const deleteMessage = (messageId) =>{
        var data = {
            'message_id': messageId,
        }
        API.post(`conversations/message/${messageId}/delete`, data).then(response => {
            setLoading(false);

            toast.success('Message delete success');
        }).catch((error) => {
            setLoading(false);
        });
    }
    return (
        <>

            <div className="dropdown" key={value.id}>
                <a
                    className="dropdown-toggle clickable text-white"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                ></a>
                <ul className="dropdown-menu" style={{}}>
                    <li>
                        <a onClick=""
                           className="dropdown-item d-flex align-items-center clickable"
                      onClick={()=>deleteMessageEveryOne(value.id)}
                        >
                            <i className="fa-solid fa-trash-can"/> Delete for everyone
                        </a>
                    </li>
                    <li>
                        <a className="dropdown-item d-flex align-items-center clickable" onClick={()=>deleteMessage(value.id)}>
                            <i className="fa-solid fa-trash-can"/> Delete for me
                        </a>
                    </li>
                </ul>
            </div>
            </>
    )
}