export default function SearchFriend({data}) {
    return(
        <>
            <div className="typed-search-box stop-propagation document-click-d-none bg-white rounded shadow-lg position-absolute top-100 w-100"
                 style={{ maxWidth:"500px"}}>
                <div className="search-preloader absolute-top-center d-none">
                    <div className="dot-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div className="search-nothing p-3 text-center fs-16 w-100"></div>
                <div id="search-content" className="text-left w-100">
                    <div className="w-100">
                        <div
                            className="px-2 py-1 text-uppercase fs-10 text-right text-muted bg-soft-secondary">Search
                            Results
                        </div>
                        <ul className="list-group list-group-raw w-100">
                            {data?.map((value, index) => (
                                <li key={index} className="list-group-item w-100">
                                    
                                    <a className="text-reset hov-text-primary d-flex align-items-center"
                                       href={`/messages/thread/${value.user_id}`}>
                                        <div class="user-picture-container rounded-circle" style={{background:`url(${value.avatar})`}}></div>
                                        <div class="name ms-1">{value.name}</div>
                                       
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            </>
    )
}