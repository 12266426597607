
import { createSlice } from '@reduxjs/toolkit';

export const chatIdSlice = createSlice({
    name:'chatId',
    initialState:{
        loading: true,
        chat_id: 0,
        error: false,
    },
    reducers:{
        CHAT_FETCH_START:(state)=>{
            state.loading=true
        },
        ADD_CHATID_SUCCESS:(state,action)=>{
            
            state.loading=false
            state.chat_id=action.payload
        },
        CHAT_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { CHAT_FETCH_START,ADD_CHATID_SUCCESS,CHAT_FETCH_ERROR }=chatIdSlice.actions;
export default chatIdSlice.reducer;