import React from "react";

export default function CallSendPopup(props){
    
    return(
        <>
        <div className="CallPopup rounded shadow">
            <div className="CallAvatar rounded-circle" style={{backgroundImage:`url(${props.image})`}}></div>
            <div className="CallerInfo">
                <h5 className="text-white">{props.name}</h5>
                <p>Calling...</p>
            </div>
            <i onClick={props.onCancel} class="fa-solid fa-phone-flip text-white bg-danger"></i>
        </div>
        </>
    );

}