
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name:'user',
    initialState:{
        loading: false,
        data: {},
        error: false,
    },
    reducers:{
        USER_FETCH_START:(state)=>{
            state.loading=true
        },
        USER_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
        },
        USER_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { USER_FETCH_START,USER_FETCH_ERROR,USER_FETCH_SUCCESS }=userSlice.actions;
export default userSlice.reducer;