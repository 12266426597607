import { ACTION_TYPES } from "./postActionTypes";

export const Video_INITIAL_STATE = {
    loading: false,
    videos: {},
    error: false,
};

export const SuggestVideoReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_START:
            return {
                loading: true,
                error: false,
                videos: {},
            };
        case ACTION_TYPES.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                videos: action.payload,
            };
        case ACTION_TYPES.FETCH_ERROR:
            return {
                error: true,
                loading: false,
                videos: {},
            };
        default:
            return state;
    }
};