import {Fragment} from "react";
import {Link,useLocation} from "react-router-dom";

export default function LeftSide() {
    const location = useLocation();
    return (
        <Fragment>
            <div className="app-left-sidebar sidebar">
                <div className="sidebar-scroll">
                    <div className="widget-area">
                        <div className="widget widget-key-features mb-4">
                    <h4>Key features</h4>
                    <div className="card shadow-sm ">
                        <div className="key-features">
                            <ul className="list-group list-group-flush">
                                <Link to={'/'}><li className="list-group-item widget-item-list "><i className="fa-solid fa-clapperboard me-2" />Social (News
                                    feed)</li></Link>
                                <Link to={'/meeting'}><li className={`list-group-item widget-item-list ${location.pathname==='/meeting'?'active':''}`}><i className="fa-solid fa-video me-2" /> Meeting</li></Link>
                                <Link to={'/messages'}><li className={`list-group-item widget-item-list ${location.pathname==='/messages'?'active':''}`}><i className="fa-solid fa-comment-dots me-2" /> Messages</li></Link>
                                <Link to={'/videos'}><li className={`list-group-item widget-item-list ${location.pathname==='/videos'?'active':''}`}><i className="fa-solid fa-play me-2" /> Videos</li></Link>
                                <Link to={'/contacts'}><li className={`list-group-item widget-item-list ${location.pathname==='/contacts'?'active':''}`}><i className="fa-solid fa-users me-2" /> Contacts</li></Link>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="widget widget-key-features mb-4">
                    <h4>Account</h4>
                    <div className="card shadow-sm">
                        <div className="Account">
                            <ul className="list-group list-group-flush">
                                <Link to={'/me'}><li className="list-group-item widget-item-list"><i className="fa-solid fa-user me-2" /> My profile</li></Link>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="widget widget-key-features mb-4">
                    <h4>Additional</h4>
                    <div className="card shadow-sm">
                        <div className="Account">
                            <ul className="list-group list-group-flush">
                                <Link to={'/terms'}><li className="list-group-item widget-item-list "><i className="fa-solid fa-circle-question me-2" /> Terms</li></Link>
                                <Link to={'/policy'}><li className="list-group-item widget-item-list"><i className="fa-solid fa-shield me-2" /> Privacy</li></Link>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>
                </Fragment>
    )
}