import {Fragment} from "react";
import { Link } from "react-router-dom";
export default function MobileNav(){
    return(
        <Fragment>
            <div className="d-flex justify-content-between align-items-center">
            <Link to={'/'} className="flex-grow-1 text-center active mobile-bottom-nav-item">
                    <i className="fa-solid fa-house" />
                    <p>Posts</p>
                </Link>
                <Link to={'/meeting'} className="flex-grow-1 text-center  mobile-bottom-nav-item">
                    <i className="fa-solid fa-desktop" />
                    <p>Meeting</p>
                </Link>
                <Link to={'/messages'} className="flex-grow-1 text-center  mobile-bottom-nav-item">
                    <i className="fa-solid fa-comment-dots" />
                    <p>Massages</p>
                </Link>
                <Link to={'/notifications'} className="flex-grow-1 text-center  mobile-bottom-nav-item">
                    <i className="fa-solid fa-bell" />
                    <p>Notifications</p>
                </Link>
                <Link to={'/me'} className="flex-grow-1 text-center  mobile-bottom-nav-item">
                    <i className="fa-solid fa-user" />
                    <p>Profile</p>
                </Link>
            </div>
        </Fragment>
    )
}