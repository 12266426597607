import React, {Component, Fragment, useEffect, useState, memo} from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContentLoader, { Facebook,Code  } from 'react-content-loader';
import {BrowserRouter as Router, Redirect, Switch, Route, Link, useParams} from "react-router-dom";
import API from "../../Network/API";
import Post from "../../Props/Post";
import { useDispatch, useSelector } from "react-redux";
import { FPOST_FETCH_ERROR, FPOST_FETCH_SUCCESS, FPOST_FETCH_SUCCESS_LOAD } from "../../Reducer/friendProfileReducer";
import DivPlaceholder from "../../Network/DivPlaceholder";


toast.configure();

export default function FriendProfile() {
    const [posts,setPosts] = useState([]);
    const [images,setImages] = useState([]);
    const [users,setUsers] = useState([]);
    const [info,setInfo] = useState("");
    let { id } = useParams();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    useEffect(() => {
        
        API.get(`/my_friend_profile/${id}`).then(response => {
            // setPosts(response.data.data.all_post)
               dispatch(FPOST_FETCH_SUCCESS(response.data.data));
                setInfo(response.data.data.personal_info)
                setImages(response.data.data.post_images)

        })

    }, [id]);

    function RemoveFriend(id){
        API.put('update_friend_request/'+id+'?status=3').then(response => {
            if(response.data.success === true){
                toast.success("Connection removed");
                window.location.reload(false);

            }
        });
    }

    function handle_request(id, action) {
        if(action === 1){

        }else if(action === 2){
            API.put(`update_friend_request/${id}?status=4`).then(response => {
                if(response.data.success === true){
                    toast.success(response.data.message);
                    window.location.reload(false);

                }
            })
        }else if(action === 3){
            API.put(`update_friend_request/${id}?status=1`).then(response => {
                if(response.data.success === true){
                    toast.success(response.data.message);
                    window.location.reload(false);

                }
            })
        }else if(action === 4){
            API.put(`update_friend_request/${id}?status=2`).then(response => {
                if(response.data.success === true){
                    toast.success(response.data.message);
                    window.location.reload(false);
                }
            })
        }else if(action === 5){
            API.post(`friend_request?user_id=${id}`).then(response => {
                if(response.data.success === true){
                    toast.success("Connect request sent successfully");
                    window.location.reload(false);
                }
            })
        }

    }
    
    function fetchRepositoriesNext() {
        setLoading(true)
        API.get(`/v1/profile_posts?user_id=${id}&page=${page+1}`)
        .then(response => {
            setPage((page+1))
            setLoading(false)
            dispatch(FPOST_FETCH_SUCCESS_LOAD(response.data.data));
        }).catch((error) => {
            dispatch(FPOST_FETCH_ERROR());
            setLoading(false)
        })
    }

    const selector = useSelector((state) => state.friendProfileReducer)
    const postsData=selector?.post_data
    return(
        <div className="story-postbox news-feed-area">
            <div className="row">
                <div className="user-profile">
                    <figure>
                        <div className="profile-cover-image" style={{backgroundImage: `url(${info.cover_image??"https://b.connectsweb.com/public/img/default-cover.jpg"})`}}>
                        </div>
                    </figure>
                    <div className="profile-section">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="profile-author">
                                    <div className="profile-author-thumb">
                                        <div className="author-img" style={{  backgroundImage: `url(${info.avatar??"https://via.placeholder.com/50x50.png"})` }} />
                                        <div className="edit-dp">
                                            <label className="fileContainer">
                                                {info.is_online?<i className="fa-solid fa-circle text-success"/>:''}

                                            </label>
                                        </div>
                                    </div>
                                    <div className="author-content">
                                        <Link className="h4 author-name" to={'#'}>{info.name}</Link>
                                        {/* <div className="author-email">{info.email}</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="profile-author-button ms-auto">


                                    {info.is_friend == 1 ?
                                        <>
                                            <div className="btn  edit-profile-btn me-2">
                                                <i className="fas fa-check me-2"/>
                                                <span>Connected</span>
                                            </div>
                                            <Link to={`/messages/thread/${info.id}`} className="btn add-story-btn me-2">
                                                <span>Message</span>
                                            </Link>
                                        </>
                                        : info.is_friend == 3 ?

                                            <Fragment>
                                                <button type={'button'}  onClick={()=>handle_request(info.id, 3)} className="btn  edit-profile-btn me-2">  <i className="fas fa-user-times me-2"/> Accept</button>
                                                <button  type={'button'} onClick={()=>handle_request(info.id, 4)} className="btn  edit-profile-btn me-2">  <i className="fas fa-user-times me-2"/> Reject</button>
                                            </Fragment>
                                        : info.is_friend == 2 ?
                                            <button onClick={()=>handle_request(info.id, 2)} type={'button'} className="btn  edit-profile-btn me-2">
                                                <i className="fas fa-xmark me-2"/>
                                                <span>Cancel Request</span>
                                            </button>
                                            :
                                            <button onClick={()=>handle_request(info.id, 5)} type={'button'} className="btn add-story-btn me-2">
                                                <i className="fas fa-user-plus me-2"/>
                                                <span>Add Contact</span>
                                            </button>
                                    }

                                    {info.is_friend == 1 ?
                                    <div className="global-dropdown">
                                        <div className="dropdown">
                                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical" /></button>
                                            <ul className="dropdown-menu">
                                                {/* <li><a className="dropdown-item d-flex align-items-center" href="javascript:void(0)"><i className="fa-solid fa-person-circle-plus" />
                                                    Follow</a></li> 
                                                 <li><a className="dropdown-item d-flex align-items-center" href="javascript:void(0)"><i className="fa-solid fa-warning" />
                                                    Report</a></li> */}
                                                
                                                <li><a className="dropdown-item d-flex align-items-center" href="javascript:void(0)" onClick={()=>RemoveFriend(info.id)}><i className="fa-solid fa-user-minus" />
                                                    Remove Connection</a></li>
                                                
                                            </ul>
                                        </div>
                                    </div>
                                    : "" }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3">
                        <div className="col-md-5">
                            <div className="about-text-profile mb-3">
                                <div className="bg-white shadow-sm rounded">
                                    <div className="card-body">
                                        <p className="p-0 m-0">{info.bio}</p>
                                        <hr />
                                        <div className="about-text-profile-list">
                                            <ul className="m-0 ps-0">
                                                <li><i className="fa-brands fa-bitbucket me-2" />{info.designation??''} {info.company_name!="" ? <> at <b>{info.company_name}</b> </> : "" } </li>
                                                <li><i className="fa-solid fa-location-dot me-2" />{info.city} </li>
                                                <li><i className="fa-solid fa-globe me-2" /><a target="_blank" href={`https://${info.web_site}`}>{info.web_site}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="view-img-profile mb-3">
                                <div className="bg-white shadow-sm rounded">
                                    <div className="card-body">
                                        <div className="view-img-profile-meta pb-2">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="view-img-profile-heading-txt">
                                                    <h6 className="p-0 mb-0">Photos</h6>
                                                </div>
                                                <div className="view-text">
                                                    <a href>View all</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            { images.length>0?
                                                images.map(img => (
                                            <div className="col-sm-6 col-6 col-md-4">
                                                <div className="view-img-profile-meta rounded">
                                                    <div className="view-img-profile-container rounded" style={{background: `url(${img.image})`}} />
                                                </div>
                                            </div>
                                                ))

                                                : '' }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            {/* <div className="profile-menu">
                                <ul className="d-flex justify-content-between profile-menu-item">
                                    <li><a href={''} className={'active-bar'}>Post</a></li>
                                    <li><a href>About</a></li>
                                    <li><a href>Photos</a></li>
                                    <li><a href>Video</a></li>
                                    <li><a href>Friends</a></li>
                                </ul>
                            </div> */}
                              {selector?.loading && [1,2,3,4,5,6]?.map((post, i) => (
                            // <DivPlaceholder key={i} backgroundColor="#ffffff"
                            //                 foregroundColor="#ecebeb"/>
                            <Facebook key={i} backgroundColor="#ffffff"
                           foregroundColor="#ecebeb"/>
                        ))}

                            {!selector?.loading && postsData?.map((post, index) => (
                                <Post data={post}/>
                                ))}


{ selector?.data.next_page_url!=null?
                                <div className={'text-center'} style={{marginBottom:'10px'}}>

                                <button  className={'btn btn-sm btn-primary'} onClick={fetchRepositoriesNext}>Load More {isLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-5"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    <span></span>
                                )}</button>
                                </div>
                                :''
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}