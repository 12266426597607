import React from "react";

const StoryUser = ({user}) => (
    <>
        <div className="col-md-3 col-4 d-none d-sm-block" key={user.id}>
            <div className="story-box story-page">
                <figure style={{background: `url(${user.image})`}}>
                    <span>{user.post_by}</span>
                </figure>
                <div className="story-thumb">
                    <div className="user-picture-container rounded-circle border border-2 border-white" style={{background: `url(${user.avatar})`}} />
                </div>
            </div>
        </div>
    </>
)
export default React.memo(StoryUser)