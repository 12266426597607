import React, {Component, Fragment, useEffect, useState, memo} from "react";
import {toast} from "react-toastify";

import API from "../../Network/API";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import {Facebook} from "react-content-loader";
import PostPlaceholder from "../../Network/PostPlaceholder";
import {Link} from "react-router-dom";

toast.configure();

export default function SlideStory() {
    const [demo_data, setDatas] = useState([{id:1},{id:2},{id:3},{id:4},{id:5}]);
    const [storyList, setStoryList] = useState([]);
    const [storyData, setStoryData] = useState([]);
    const [fileImage, setFleImage] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [modalDialog, setModalDialog] = useState(false);
    const [progress, setProgress] = useState(false);
    useEffect(() => {
        getInitailStory()
    }, []);

    function getInitailStory() {
        setProgress(true)
            API.get('/stories').then((response) => {
                setProgress(false)
                setStoryList(response.data.data);
            }).catch((error) => {
                setProgress(true)
            });
    }


    const onCreatePostStory = (fileImage) => {
        setLoading(true)
        const fd = new FormData();
        if (fileImage!=null) {
            fd.append('image', fileImage, fileImage.name);
        }else{
            return false;
        }
        API.post("/stories", fd, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
            }
        }).then((response) => {
               setLoading(false)
                if (response.data.success === true) {
                    toast.success(response.data.message);
                    window.location = "/";
                }else{
                    toast.error('something was wrong!');
                }
            })
            .catch((err) => {
                toast.error('something was wrong!');
                setLoading(false)
            });
    }

    const fileSelectedHandler = (e) => {
        setFleImage(e.target.files[0])
       onCreatePostStory(e.target.files[0]);
    }
    const fileInput = React.useRef();
    const onClick = () => {
        fileInput.current.click();
    }

    const toggleModal = () => {
        setModalDialog(!modalDialog)
    }

    const  getStory = (index) => {
        API.get('/story/show/'+index).then(response => {
            setStoryData(response.data.data)
           toggleModal();
        })
    }

    return(
        <>
        <div className="row justify-content-center">
        <div className="col-10 news-feed-post bg-white position-relative">
          <div className="row g-2 p-3">
            
            <div className="col-md-3 col-4  col-sm-4 single-story">
              <div className="story-box">
                        <figure style={{backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})`}} onClick={onClick}>
                            <span className="add-story-text">Add Your Story</span>
                        </figure>
                        <div className="add-story-thumb">
                            {isLoading ?
                                <span
                                    className="spinner-border spinner-border-sm ml-5"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                :
                                <i className="fas fa-plus"/>
                            }
                        </div>
                    </div>
                    <input type="file"  accept="image/*" style={{display: 'none'}}
                       onChange={fileSelectedHandler} ref={fileInput}/>
            </div>

            {progress && [1,2,3]?.map((post,i) => (
                    <div className="col-md-3 col-4  col-sm-4 single-story" key={Math.random()}>
                        <div className="story-box">
                            <figure className={'loads-story'} style={{backgroundColor: `#ffffff`}}>
                                <span></span>
                            </figure>
                            <div className="story-thumb ">
                                <div className="user-picture-container rounded-circle border border-2 border-white" style={{backgroundColor: `#ffffff`}} />
                            </div>

                        </div>
                    </div>
                ))}

{
                    !progress && storyList.slice(0,3).map((value, index) => (
                <div className="col-md-3 col-4  col-sm-4 single-story" key={index}>
                    <div className="story-box">
                        <figure style={{backgroundImage: `url(${value.image})`}}>
                            <span>{value.post_by}</span>
                        </figure>
                        <div className="story-thumb">
                            <div className="user-picture-container rounded-circle border border-2 border-white" style={{backgroundImage: `url(${value.avatar})`}} />
                        </div>
                       
                    </div>
                </div>
                    ))
                }

            
            
          </div>
          <Link to={'/all-story'} className="story-next-arrow">
                                <i className="fas fa-arrow-right"></i>
                            </Link>
        </div>
      </div>
        </>
    )
}