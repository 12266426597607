import React, { useEffect } from "react";
import API from "../Network/API";
import { useParams } from "react-router-dom";
import Post from "../Props/Post";

import { SINGLE_POST_FETCH_ERROR, SINGLE_POST_FETCH_SUCCESS } from "../Reducer/singlePostReducer";
import { useDispatch, useSelector } from "react-redux";

export default function SinglePost() {
 
    let { id } = useParams();
    const dispatch=useDispatch();

    useEffect(() => {
        API.get(`/get_post_comments/${id}`).then(response => {
            dispatch(SINGLE_POST_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(SINGLE_POST_FETCH_ERROR());
        });
    }, [id]);

    const state = useSelector((state) => state.singlePostReducer)

    return (
        <>
            <div className="row d-flex justify-content-center">
                <div className="col-md-10">
            <div className="story-postbox news-feed-area">
                <div className="row justify-content-md-center">
                    <div className="col-md-10">
                        <Post key={1} data={state.post_data} />
                    </div>
                </div>
            </div>
            </div>
            </div>

        </>
    )

}