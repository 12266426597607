import StoryUser from "./StoryUser";
import React, {useEffect, useState} from "react";
import API from "../../Network/API";

export default function AllStory() {
    const [progress, setProgress] = useState(false);
    const [page, setPage] = useState(1);
    const [nextPageUrl, setNextPageURL] = useState('');
    const [lengthData, setDataLength] = useState(0);
    const [demo_data, setDatas] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}]);
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        fetchStory();
    }, []);

    function fetchStory() {
        setProgress(true)
        API.get('stories', {
            'offset': posts.length
        }).then((response) => {
            const paginator = response.data.data;
            setDataLength(paginator.total)
            if (paginator.length) {
                setPosts(paginator)
                setProgress(false)
                // setNextPageURL(paginator.next_page_url)
                // setPage((page + 1))
            }
        }).catch((error) => {
            setProgress(false)
        });

    }

    return (
        <>
            <div className="story-postbox news-feed-area middle-scroll">
                <div className="row">
                    <div className="friend_list-heading px-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="friend_list-heading-text">
                                <h4>Story</h4>
                            </div>
                            <div className="message-people-srch">
                                <div className="messages-search-box">
                                    <form>
                                        <input type="text" className="input-search" placeholder="Search Message..."/>
                                        <button type="submit">
                                            <i className="fa-solid fa-magnifying-glass"/>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {progress && demo_data?.map((post, i) => (
                        <div className="col-md-3 col-sm-4 col-12">
                            <div className="story-box">
                                <figure className={'loads-story'} style={{backgroundColor: `#ffffff`}}>
                                    <span></span>
                                </figure>
                                <div className="story-thumb ">
                                    <div className="user-picture-container rounded-circle border border-2 border-white"
                                         style={{backgroundColor: `#ffffff`}}/>
                                </div>

                            </div>
                        </div>
                    ))}
                    {
                        !progress && posts?.map((value, index) => (
                            <StoryUser key={index} user={value}/>
                        ))
                    }

                </div>
            </div>
        </>
    )
}