import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,

} from "reactstrap";
import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';
import getCroppedImg from './utils/cropImage';

//cover width=730px vs 250px
//profile 500/500
// https://www.npmjs.com/package/react-easy-crop
// https://github.com/nurkarim/react-firebase-images-gallery/blob/complete-project/src/components/crop/CropEasy.js
const CoverCrop = ({ coverURL, coverShowB, isModalVisibleCover,_profile_cover }) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [loading, setLoading] = useState(0);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const cropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
      }, []);

    // const cropComplete = (croppedArea, croppedAreaPixels) => {
    //     setCroppedAreaPixels(croppedAreaPixels);
    // };

    const cropImage = useCallback( async () => {
        setLoading(true);
        try {
            const { file, url } = await getCroppedImg(
                coverURL,
                croppedAreaPixels,
                rotation
            );
            _profile_cover(file)
            coverShowB(false);
        } catch (error) {

            console.log(error);
        }

        setLoading(false);
    }, [croppedAreaPixels, rotation, coverURL]);
    return (
        <Modal
            isOpen={isModalVisibleCover}
            size=""
            toggle={coverShowB}
            backdrop="static" className="modal-dialog-scrollable popup-max-height"
        >
            <ModalHeader toggle={coverShowB} id={'staticBackdropLabel'}>
                Update Cover Photo
            </ModalHeader>
            <ModalBody style={{minHeight:'350px'}}>
            <div className="crop-App">
      <div className="crop-container">
                
                    <Cropper
                        image={coverURL}
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        aspect={2.92 / 1}
                       
                        onZoomChange={setZoom}
                        onRotationChange={setRotation}
                        onCropChange={setCrop}
                        onCropComplete={cropComplete}
                        zoomWithScroll={true}
                        showGrid={true}
                    />

                </div>
                </div>

                <div className="controls-crop">
                    <div className="form-group">
                    <label>Zoom:{zoomPercent(zoom)}</label>
                    <RangeSlider
                        valueLabelDisplay="auto"
                        valueLabelFormat={zoomPercent}
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onChange={(e, zoom) => setZoom(zoom)}
                        aria-labelledby="Zoom"
                        className="zoom-range form-control"
                    />
                    </div>
                </div>

                {/* <div className="controls-crop-rotation">
                <div className="form-group">
                    <label>Rotation: {rotation + '°'}</label>
                    <RangeSlider
                        min={0}
                        max={360}
                        value={rotation}
                        onChange={(e, rotation) => setRotation(rotation)}
                        className="zoom-range form-control"
                    />
                     </div>
                </div> */}

            </ModalBody>
            <ModalFooter className={'post-footer-comment-icon'}>
                <button className="btn btn-primary rounded-1" onClick={cropImage}>Crop {loading ? (
                    <span
                        className="spinner-border spinner-border-sm ml-5"
                        role="status"
                        aria-hidden="true"
                    ></span>
                ) : (
                    <span></span>
                )}</button>

            </ModalFooter>
        </Modal>
    );
};

export default CoverCrop;

const zoomPercent = (value) => {
    return `${Math.round(value * 100)}%`;
};