import { createSlice } from '@reduxjs/toolkit';

export const fpostSlice = createSlice({
    name: 'friend_post',
    initialState: {
        loading: true,
        data: {},
        post_data: [],
        error: false,
    },
    reducers: {
        FPOST_FETCH_START: (state) => {
            state.loading = false
        },
        FPOST_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.post_data=action.payload.all_post
        },
        FPOST_FETCH_SUCCESS_LOAD: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.post_data=[ ...state.post_data, ...action.payload.all_post]
        },
        ADD_FPOST_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
            state.post_data=[action.payload,...state.post_data]
        },
        FPOST_FETCH_COMMENT_ADDON: (state, action) => {
            // console.log(action.payload)
            state.loading = false
            state.post_data=state.post_data.map((post) =>
            post.id === action.payload.post_id
              ? post.last_comments.push(action.payload) && post
              : post
          )
        },
        

        DELETE_FPOST_COMMENT:(state,action)=>{
            const Post = state.post_data.find(
                (pst) => pst.id === action.payload.post_id
              );
              Post.last_comments.filter((com)=>com.id !== action.payload.comment_id);
              state.loading = false
              state.post_data=state.post_data.map((pst) =>
              pst.id === action.payload.post_id ? Post : pst
            )
        },

        DELETE_FPOST:(state,action)=>{
            const filterPosts = state.post_data.filter(
                (post) => post.id !== action.payload
              );
              state.loading = false
            state.post_data=filterPosts
        },

        ADD_FPOST_REPLY:(state,action)=>{
           
            state.post_data=state.post_data.map((post) =>
            post.id === action.payload.post_id
              ? post.last_comments.map((comment) =>comment.id === action.payload.comment_id?comment.comment_reply.push(action.payload):comment) && post
              : post
          )
             
        },

        DELETE_FPOST_REPLY:(state,action)=>{
            
            const Post = state.post_data.find(
                (pst) => pst.id === action.payload.post_id
              );
              console.log('rp='+action.payload.comment_reply_id);
              Post.last_comments.map((com)=>com.comment_reply.splice(com.comment_reply.findIndex((fruit) => fruit.id === action.payload.comment_reply_id),1));
              
              state.post_data=state.post_data.map((pst) =>
              pst.id === action.payload.post_id ? Post : pst
            )
            
        },

        FPOST_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { FPOST_FETCH_START,DELETE_FPOST_REPLY,ADD_FPOST_FETCH_SUCCESS, FPOST_FETCH_ERROR, FPOST_FETCH_SUCCESS,FPOST_FETCH_SUCCESS_LOAD,FPOST_FETCH_COMMENT_ADDON,ADD_FPOST_REPLY,DELETE_FPOST_COMMENT,DELETE_FPOST } = fpostSlice.actions;
export default fpostSlice.reducer;