
     
    const Loader = () => {
    
      return (
        <div className='divLoader'>
      <svg className='svgLoader' viewBox="0 0 1024 1024" width="10em" height="10em">
        <path fill="lightblue"
          d="PATH FOR THE LOADER ICON"
        />
      </svg>
    </div>
      );
    };
    
    export default Loader;