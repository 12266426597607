import { Fragment } from 'react';
import ContentLoader  from 'react-content-loader';

export default function Placeholder1(height,width){
    return (
        <>

            <ContentLoader
                speed={3}
                width={width}
                height={height}
                viewBox="0 0 300 280"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="3" y="3" rx="10" ry="10" width="300" height="200" />

            </ContentLoader>

        </>
    );
}