import React, { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import API from "../../Network/API";
import { useEffect } from "react";
import { DELETE_COMMENT, POST_FETCH_COMMENT_ADDON } from "../../Reducer/postReducer";
import { FPOST_FETCH_COMMENT_ADDON } from "../../Reducer/friendProfileReducer";
import { SINGLE_DELETE_COMMENT, SINGLE_POST_FETCH_COMMENT_ADDON } from "../../Reducer/singlePostReducer";
import { useDispatch } from "react-redux";

function PostView(props) {

    const [love_react, setLoveReact] = useState(false);
    const [comment_love_react, setCommentLoveReact] = useState(false);
    const [reply_comment_delete, setReplyCommentDelete] = useState(false);
    const [reply_comment_id, setReplyCommentID] = useState(false);
    const [comment_love_react_add, setCommentLoveReactAdd] = useState(0);

    const [write_comment, setWriteComment] = useState(null);
    const [reply_comment, setReplyComment] = useState(null);

    const [p_id_like, setPIDLike] = useState(0);
    const [p_id_com, setPIDCom] = useState(0);
    const [set_like, setLike] = useState(0);
    const [last_comments, setLastComments] = useState([]);
    const [set_comment, setComment] = useState(0);

    const [postId, setPostId] = useState(0);
    const [post_modalDialog, setPostShareModalDialog] = useState(false);
    const [show, setShow] = useState(false);
    const [show_comment_box, setShowCommentBox] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const [is_comment_loading, setCommentLoading] = useState(false);
    const [reply_com_id, setReplyComID] = useState(0);
    const [comment_id, setCommentID] = useState(0);
    const [is_com_delete, setISComDelete] = useState(false);
    const [comment_page, setCommentPage] = useState(2);
    const [next_page_url, setNextPageURL] = useState(null);
    const [slider_index, setSliderIndex] = useState(-1);

    const toggleModalSharePost = () => {
        setPostShareModalDialog(!post_modalDialog)
    };
    const dispatch = useDispatch();
    const data = props.data;
    useEffect(() => {
        setLastComments(data.last_comments)
        if (data.last_comments?.length > 10) {
            setNextPageURL('/post_comment_history?page=2');
        }
        // const echo = new Echo({
        //     broadcaster: 'pusher',
        //     key: 'key56556563562563624376',
        //     wsHost: 'b.connectsweb.com',
        //     wssHost: 'b.connectsweb.com',
        //     wsPort: 6001,
        //     wssPort: 443,
        //     forceTLS: true,
        //     encrypted: true,
        //     disableStats: true,
        // });
        // echo
        //     .channel(`App.PostComment.${data.id}`)
        //     .subscribed(() => {
        //         console.log('You are subscribed'+data.id);
        //     }).listen('PostCommentEvent', (value) => {
        //         console.log(value.data);
        //         setLastComments((last_comments) => [...last_comments, value.data]);
        //     });

    }, [data]);


    function fetchPostCommentNext(postId) {

        setCommentLoading(true)

        API.get('post_comment_history/?page=' + comment_page + '&post=' + postId, {
            'offset': last_comments.length
        }).then((response) => {
            setCommentLoading(false)
            const paginator = response.data.data;
            if (paginator.get_posts.length) {
                setLastComments([...last_comments, ...paginator.get_posts])
                setNextPageURL(paginator.next_page_url)
                setCommentPage((comment_page + 1))
            }
        }).catch((error) => {
            setCommentLoading(false)
        });

    }


    const postLike = (post_id) => {
        setLoading(true)
        setLoveReact(true)
        setLike(1)
        setPIDLike(post_id)
        API.post("/post_likes", {
            post_id: post_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            setLoveReact(true)
            if (response.data.success === true) {
                toast.success('Post loved Successfully');
            } else {
                setLoveReact(false)
            }
        });
    }

    const toggleReply = (com_id) => {
        setShow(!show)
        setPIDCom(com_id)
    };

    const toggleComment = (post_id) => {
        setShowCommentBox(!show_comment_box)
        setPostId(post_id)
    };


    const commentLike = (post_id, comment_id) => {
        setLoading(true)
        setCommentLoveReact(true)
        setPIDCom(comment_id)
        setCommentLoveReactAdd(1)
        API.post("/comment_like", {
            post_id: post_id,
            comment_id: comment_id,
            react_icon: 1,
        }).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                toast.success('Comment loved Successfully');
            }
        });
    }

    const commentsReplyDelete = (comments_id) => {
        setLoading(true)
        API.delete("/reply_comment_delete/" + comments_id).then((response) => {
            if (response.data.success === true) {
                setLoading(true)
                setReplyCommentDelete(true)
                setReplyCommentID(comments_id)
                toast.success('Comment delete successfully');
            }
        });
    }

    const handleReplyMessage = (p_id, cm_id) => {
        setLoading(true)
        setReplyComID(cm_id)
        API.post("/comment_reply", {
            post_id: p_id,
            comment_id: cm_id,
            comment: reply_comment,
        }).then((response) => {
            setLoading(false)
            setReplyComment('')
            if (response.data.success === true) {

                toast.success('reply sent successfully');
            }
        });
    }

    const submit_comment = (p_id) => {
        if (write_comment === null) {
            toast.success('Blank not accepted');
            return false;
        }
        setLoading(true)
        setComment(1)
        API.post("/post_comments", {
            post_id: p_id,
            comment: write_comment,
        }).then((response) => {
            setLoading(false)
            setWriteComment("")
            if (response.data.success === true) {
                dispatch(POST_FETCH_COMMENT_ADDON(response.data.data))
                dispatch(FPOST_FETCH_COMMENT_ADDON(response.data.data))
                dispatch(SINGLE_POST_FETCH_COMMENT_ADDON(response.data.data))
                setPostId(p_id)
                toast.success('Comment sent successfully');
            }
        });

    }


    const commentsDelete = (comments_id, post_id) => {
        setLoading(true)
        API.delete("/comment_delete/" + comments_id).then((response) => {
            setLoading(false)
            if (response.data.success === true) {
                setISComDelete(true)
                setCommentID(comments_id)
                setPostId(post_id)
                setComment(-1)
                const comPayLod={
                    comment_id:comments_id,
                    post_id:post_id,
                }
                dispatch(DELETE_COMMENT(comPayLod))
                dispatch(SINGLE_DELETE_COMMENT(comPayLod))
                toast.success('Comment delete successfully');
            }
        });
    }

    const share_post = (p_id) => {
        setPostId(p_id);
        toggleModalSharePost()
    }


    function nextSlider(n) {

        if (slider_index == -1) {
            var dd = props.imageIndex + n
            if (dd <= data.images?.length) {
                setSliderIndex(dd)
            }
        } else {
            var dd = slider_index + n
            if (dd <= data.images?.length) {
                setSliderIndex(dd)
            }

        }



    }




    return (

        <>
            <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-xl postpop-fixed-height'}
            >
                <ModalBody className="modal-body p-0">
                    <div className="post-popup">
                        <div className="post-popup-display">
                            <div className="w-60-postpopup position-relative h-100">
                            <button type="button" className="btn-close my-custom-close-btn-mobile" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleShowB} />
                                {data.images?.length > 1 ?
                                    <>
                                        <div className="left-arrow position-absolute postpopup-arrow-icon" onClick={() => nextSlider(-1)}>
                                            <i className="fa-solid fa-angle-left" />
                                        </div>

                                        <div className="right-arrow position-absolute postpopup-arrow-icon" onClick={() => nextSlider(1)}>
                                            <i className="fa-solid fa-angle-right" />
                                        </div>

                                    </>
                                    : ''}

                                <div className="postPopup-img">


                                    {
                                        data.images?.length > 0 ?
                                            <>
                                               

                                                        {slider_index > -1 ?

                                                            <>
                                                                {slider_index<data.images?.length ?
                                                                    <img src={data.images[slider_index]?.image} />
                                                                    : 
                                                                    <img src={data.images[slider_index-1]?.image} />
                                                                    }

                                                            </>


                                                            :
                                                            <img src={data.images[props.imageIndex]?.image} />
                                                        }
                                                  
                                            </>
                                            :
                                            ''}

                                </div>
                            </div>
                            <div className="w-40-postpopup">
                                <div className="news-feed-area">
                                    <div className="news-feed news-feed-post bg-white mb-3">
                                        <div className="post-header d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center justify-content-start">
                                                <div className="image">
                                                    <Link to={`/contact/${props.data.user_id}`}>
                                                        <div className="user-picture-container rounded-circle"
                                                            style={{ backgroundImage: `url(${props.data.avatar})`, objectFit: 'fill' }} />
                                                    </Link>
                                                </div>
                                                <div className="info ms-3">
                                                    <span className="name">
                                                        <Link to={`/contact/${props.data.user_id}`}>{props.data.post_by}
                                                            {props.data.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                                            {props.data.is_shared_post ?
                                                                <span className=""><small className="fw-100 text-grey"> shared </small> {props.data.root_user_name} <Link to={`/post/${props.data.share_post_id}`}><span className="fw-700 text-grey-900">Post</span></Link></span> : ""}
                                                        </Link>
                                                    </span>
                                                    <span className="small-text"><a href="javascript:void(0)">{props.data.post_time}</a></span>
                                                </div>
                                            </div>
                                            <button type="button" className="btn-close my-custom-close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleShowB} />
                                        </div>
                                        <div className="postPopup-body-scroll">
                                        <div className="postPopup-body-scroll-hover">
                                            <div className="post-body">
                                                <p className="post-text">{props.data.is_shared_post ? props.data.self_details : props.data.details}</p>

                                                <div className="post-meta-react-count d-flex justify-content-between align-items-center">
                                                    <div className="post-meta-react-count-love">
                                                        <a href="javascript:void(0)"><i className="fa-solid fa-heart" /><span
                                                            className="number">{data.totalLikes + set_like} </span></a>
                                                    </div>
                                                    <div className="post-meta-react-count-comment">
                                                        <a href="javascript:void(0)">{data.totalComments + set_comment} Comments</a>
                                                    </div>
                                                </div>

                                                <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                                    <li className="post-react">
                                                        {data.is_like ?
                                                            <><i className="fa-solid fa-heart i-loved" /><span>Love</span></>
                                                            : <a className={'clickable'} onClick={() => postLike(data.id)}><i
                                                                className={`fa-solid fa-heart ${love_react && p_id_like === data.id ? 'i-loved' : ''}`} /><span>Love</span></a>}
                                                    </li>
                                                    <li className="post-comment">
                                                        <a className={'clickable'} onClick={() => toggleComment(data.id)}><i
                                                            className="fa-solid fa-comment" /><span>Comment</span></a>
                                                    </li>
                                                    <li className="post-share">
                                                        <a className={'clickable'} onClick={() => share_post(data.id)}><i className="fa-solid fa-share-nodes" /><span>Share</span></a>
                                                    </li>
                                                </ul>

                                                {last_comments?.length > 0 ?
                                                    <>
                                                        <div style={{ height: '10px' }}></div>
                                                        {last_comments?.slice(0, 10)?.map((getcomment, i) => (
                                                            <>
                                                                <div key={i}
                                                                    className={`post-comment-list ${is_com_delete && comment_id === getcomment.id ? 'd-none' : ''} `}
                                                                    id={"comments_" + getcomment.post_id}>
                                                                    <div className="comment-list d-flex justify-content-between">
                                                                        <div className="comment-image">
                                                                            <Link to={`/contact/${getcomment.comment_user_id}`}>
                                                                                <div className="user-picture-container rounded-circle"
                                                                                    style={{ backgroundImage: `url(${getcomment.avatar})` }} />
                                                                            </Link>
                                                                        </div>
                                                                        <div className="comment-list-meta">
                                                                            <div className="comment-info shadow-sm">
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="name">
                                                                                        <Link to={`/contact/${getcomment.comment_user_id}`}>{getcomment.comment_by}</Link>
                                                                                        {getcomment.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                                                                    </div>
                                                                                    <a className="time">{getcomment.comment_time}</a>
                                                                                </div>
                                                                                <p>{getcomment.comment}</p>
                                                                            </div>
                                                                            <ul className="comment-react">
                                                                                <li className="post-react">
                                                                                    {getcomment.is_like ?
                                                                                        <a className={'clickable'}><i
                                                                                            className="fa-solid fa-heart" /><span>Love</span>
                                                                                            <span
                                                                                                className="number"> {getcomment.total_likes} </span></a>
                                                                                        :
                                                                                        <a className={'clickable'}
                                                                                            onClick={() => commentLike(data.id, getcomment.id)}><i
                                                                                                className={`fa-solid fa-heart ${p_id_com === getcomment.id && comment_love_react ? 'i-loved' : 'text-dark'} `} /><span>Love</span>
                                                                                            <span
                                                                                                className="number"> {p_id_com === getcomment.id && comment_love_react ? getcomment.total_likes + comment_love_react_add : getcomment.total_likes > 0 ? getcomment.total_likes : ''} </span></a>
                                                                                    }

                                                                                </li>
                                                                                <li className="post-comment">
                                                                                    <a className={'clickable'}
                                                                                        onClick={() => toggleReply(getcomment.id)}><i
                                                                                            className="fa-solid fa-comment" />Reply{getcomment.comment_reply?.length > 0 ? `(${getcomment.comment_reply?.length})` : ''} <span
                                                                                                className="number"> </span></a>
                                                                                </li>
                                                                                <li className="post-comment-replay-icon">
                                                                                    <a href="#0"><i className="fa-solid fa-angle-down" /></a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        {getcomment.is_deletable === 1 ?
                                                                            <div className="comment-button">
                                                                                <div className="dropdown">
                                                                                    <button className="dropdown-toggle" type="button"
                                                                                        data-bs-toggle="dropdown"
                                                                                        aria-haspopup="true" aria-expanded="false"><i
                                                                                            className="fa-solid fa-ellipsis-vertical" /></button>
                                                                                    <ul className="dropdown-menu">

                                                                                        <li><a className="dropdown-item d-flex align-items-center clickable"
                                                                                            onClick={() => commentsDelete(getcomment.id, data.id)}><i
                                                                                                className="fa-solid fa-trash-can" /> Delete </a></li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                    </div>
                                                                </div>

                                                                {getcomment.comment_reply?.length > 0 ?
                                                                    <>
                                                                        {getcomment.comment_reply?.map((reply, i) => (
                                                                            <>
                                                                                <div key={Math.random()} id={`reply${reply.id}`}
                                                                                    className={`post-comment-list post-comment-replay ${p_id_com === getcomment.id && show ? '' : 'd-none'} ${reply_comment_delete && reply_comment_id === reply.id ? 'd-none' : ''}`}>
                                                                                    <div className="comment-list d-flex justify-content-between">
                                                                                        <div className="comment-image">
                                                                                            <Link to={`/contact/${reply.comment_user_id}`}>
                                                                                                <div
                                                                                                    className="user-picture-container rounded-circle"
                                                                                                    style={{ backgroundImage: `url(${reply.avatar})` }} />
                                                                                            </Link>
                                                                                        </div>
                                                                                        <div className="comment-list-meta">
                                                                                            <div className="comment-info shadow-sm">
                                                                                                <div className="d-flex justify-content-between">
                                                                                                    <div className="name">
                                                                                                        <Link
                                                                                                            to={`/contact/${reply.comment_user_id}`}>{reply.comment_by}</Link>
                                                                                                        {reply.is_online ? <i className="fa-solid fa-circle" /> : ''}
                                                                                                    </div>
                                                                                                    <span className="time"
                                                                                                    >{reply.comment_time}</span>
                                                                                                </div>
                                                                                                <p>{reply.comment}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        {reply.is_deletable ?
                                                                                            <div className="comment-button">
                                                                                                <div className="dropdown">
                                                                                                    <button className="dropdown-toggle"
                                                                                                        type="button"
                                                                                                        data-bs-toggle="dropdown"
                                                                                                        aria-haspopup="true"
                                                                                                        aria-expanded="false">
                                                                                                        <i
                                                                                                            className="fa-solid fa-ellipsis-vertical" />
                                                                                                    </button>
                                                                                                    <ul className="dropdown-menu">

                                                                                                        <li><span
                                                                                                            onClick={() => commentsReplyDelete(reply.id)}
                                                                                                            className="dropdown-item d-flex align-items-center clickable"
                                                                                                        ><i
                                                                                                                className="fa-solid fa-trash-can" /> Delete</span>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div> : ""
                                                                                        }
                                                                                    </div>
                                                                                </div>


                                                                            </>

                                                                        ))}


                                                                    </> : ""
                                                                }
                                                                <div
                                                                    className={`post-comment-list post-comment-replay  ${p_id_com === getcomment.id && show ? '' : 'd-none'}`}>
                                                                    <form className="post-footer d-flex justify-content-between">
                                                                        <div className="footer-image">
                                                                            <Link to="#0">
                                                                                <div
                                                                                    className="user-picture-container rounded-circle"
                                                                                    style={{ backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})` }} />
                                                                            </Link>
                                                                        </div>
                                                                        <div className="form-group post-footer-comment-form">
                                                                            <textarea name="message" className="form-control"
                                                                                placeholder="Write a comment..."
                                                                                defaultValue={'@' + getcomment.comment_by + ' '} value={reply_comment} onChange={(e) => setReplyComment(e.target.value)} />
                                                                            <label className="post-footer-comment-icon">
                                                                                <a href="#0"><i
                                                                                    className="fa-regular fa-face-smile" /></a>
                                                                                <span className="horizontal-divided" />
                                                                                <button type="button" onClick={() => handleReplyMessage(data.id, getcomment.id)} className="post-footer-comment-submit">Post  {isLoading && reply_com_id === getcomment.id ? (
                                                                                    <span
                                                                                        className="spinner-border spinner-border-sm ml-5"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    ></span>
                                                                                ) : (
                                                                                    <span></span>
                                                                                )}
                                                                                </button>
                                                                            </label>
                                                                        </div>
                                                                    </form>
                                                                </div>

                                                            </>
                                                        ))}
                                                        {data.last_comments?.length > 10 && next_page_url != null ?
                                                            <div className="view-comment-text text-center">
                                                                <a className={'clickable'} onClick={() => fetchPostCommentNext(data.id)}>View more{is_comment_loading ? (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm ml-5"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                ) : (
                                                                    <span></span>
                                                                )}</a>
                                                            </div>
                                                            : ''}
                                                    </>
                                                    : <a className={`clickable ${postId === data.id && show_comment_box ? 'd-none' : ''}`}
                                                        style={{ fontSize: '10px' }} onClick={() => toggleComment(data.id)}>Be the first to comment
                                                        on this</a>
                                                }

                                            </div>
                                            <div
                                                className={`post-footer d-flex justify-content-between `} style={{ marginBottom: '50px' }}>
                                                <div className="footer-image">
                                                    <a href="javascript:void(0)">
                                                        <div className="user-picture-container rounded-circle"
                                                            style={{ backgroundImage: `url(${localStorage.getItem("profile_img") ?? "https://via.placeholder.com/50x50.png"})` }} />
                                                    </a>
                                                </div>
                                                <div className="form-group post-footer-comment-form">
                                                    <textarea name="message" className="form-control" placeholder="Write a comment..."
                                                        value={write_comment} onChange={(e) => setWriteComment(e.target.value)}
                                                    />
                                                    <label className="post-footer-comment-icon">
                                                        <a href="javascript:void(0)"><i className="fa-regular fa-face-smile" /></a>

                                                        <span className="horizontal-divided" />
                                                        <button type="button" onClick={() => submit_comment(data.id)} className="post-footer-comment-submit">Post
                                                        </button>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
export default React.memo(PostView)