import Pusher from 'pusher-js';
import Echo from "laravel-echo";
export default function createSocketConnection() {
    if (!window.Echo) {
        const token = localStorage.getItem("connect_token");
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: 'key56556563562563624376',
            wsHost: 'b.connectsweb.com',
            wssHost: 'b.connectsweb.com',
            wsPort: 6001,
            wssPort: 443,
            forceTLS: true,
            encrypted: true,
            disableStats: true,
            authEndpoint: 'https://b.connectsweb.com/broadcasting/auth',
            auth: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        });
    }
}