import React, {useState} from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import API from "../../Network/API";
import TimeZone from "../../CustomHooks/TimeZone";
import MeetingShare from "./MeetingShare";

export default function AddMeetingSchedule(props){
    const [modalDialog, setModalDialog] = useState(false);

        const toggleModal = () => {
            setModalDialog(!modalDialog)
        };
        
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(null);
    const [date, setDate] = useState(null);
    const [time_zone, setTimeZone] = useState('Europe/London');
    const [time, setTime] = useState(null);
    const [password, setPassword] = useState('');
    const [note, setNote] = useState('');
    const [sendToShareData, setSendToShareData] = useState('');
    // const [data] = useFetch("https://jsonplaceholder.typicode.com/todos");
    const fileObj = [];
    const fileArray = [];
    const fileArrayAnother = [];
    const [file, setFile] = useState([]);

    const handleSubmit = event => {
        event.preventDefault();
        
        if (typeof title === null) {
            toast.error('Please add title.');
            return false;
        }
        if (typeof time_zone === null) {
            toast.error('Please add time_zone.');
            return false;
        }
        const fd = new FormData();
        fd.append('title',title);
        fd.append('date',date);
        fd.append('time_zone',time_zone);
        fd.append('time',time);
        fd.append('note',note);
        fd.append('password',null);

        setLoading(true);
        if (fileObj.length>0) {
            for (var i = 0; i < fileObj.length; i++) {
                // console.log(fileObj)
                // console.log(fileObj[i].name)
                fd.append('img', fileObj[i], fileObj[i].name);
            }
        }

        API.post('myScheduleCreate',fd).then(response => {
            setLoading(false);
            if (response.data.success === true) {
                props.handleShowB()
                  console.log(response.data.data);
                setSendToShareData(response.data.data)
                toggleModal()
            }
        }).catch((error) => {
            setLoading(false);
            toast.error('Please try again.');
        });
    }

    const uploadHandler = (e) => {
        fileObj.pop();
        fileObj.push(e.target.files[0])

    }


    
    return (
        <>
 <Modal
            isOpen={props.isModalVisible}
            size=""
            toggle={props.handleShowB}
            className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
        >
            <ModalHeader className="bg-light" toggle={props.handleShowB} id={'staticBackdropLabel'}>
            Schedule Your Meeting
            </ModalHeader>
            <ModalBody className="bg-light">
            <div className="meeting-schedule-popup">
            <form onSubmit={handleSubmit}>
          <div className="row mb-3 g-2">
            <p><span className="text-danger">*</span> marked are required</p>
            <div className="col-6">
              <label htmlFor="Title" className="form-label form-label-font-14">Meeting Title <span className="text-danger">*</span></label>
              <input type="text" onChange={(e) => setTitle(e.target.value)}  className="form-control form-control-font-12" id="Title" placeholder="Enter your meeting name" required/>
            </div>
            <div className="col-3">
              <label htmlFor="date" className="form-label form-label-font-14">Date <span className="text-danger">*</span></label>
              <input type="date" onChange={(e) => setDate(e.target.value)}  className="form-control form-control-font-12" id="date" placeholder="Enter your meeting room" required/>
            </div>
            <div className="col-3">
              <label htmlFor="time" className="form-label form-label-font-14">Time <span className="text-danger">*</span></label>
              <input type="time" onChange={(e) => setTime(e.target.value)}  className="form-control form-control-font-12" id="time" placeholder="Enter your meeting room" required/>
            </div>
          </div>
          <div className="row mb-3 g-2">
            <div className="col-6">
              <label htmlFor="Description" className="form-label form-label-font-14">Description</label>
              <input type="text" onChange={(e) => setNote(e.target.value)}  className="form-control form-control-font-12" id="Description" placeholder="Enter your Description" />
            </div>
            <div className="col-6">
              <label htmlFor="inputState" className="form-label form-label-font-14">Time Zone <span className="text-danger">*</span></label>
              <select id="inputState" onChange={(e) => setTimeZone(e.target.value)} defaultValue="Europe/London" className="form-select form-control-font-12" required>
              {TimeZone()?.map((zoneName,index) =>
                <option key={index} value={zoneName}>{zoneName}</option>
                )}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label htmlFor="cvpotos" className="form-label form-label-font-14">Cover Photos</label>
              <div className="meeting-cover-photos position-relative" id="cvpotos">
                <input  className="meeting-cover-photos-choose-file" type="file" onChange={uploadHandler} accept="image/*"/>
                <div className="meeting-cover-photos-body">
                  <div className="text-center">
                    <i className="fas fa-cloud-upload" />
                    <span>Drag an image here or upload a file</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalFooter className={'post-footer-comment-icon bg-light'}>
            <button type={'submit'} className="btn btn-primary w-100 mb-3 btn-lg" role="button">Next {loading ? (
                                                    <span
                                                        className="spinner-border spinner-border-sm ml-5"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                ) : (
                                                    <span></span>
                                                )}</button>

            </ModalFooter>
        </form>
      </div>
            </ModalBody>
            
        </Modal>

        <MeetingShare modalVisible={modalDialog} handleShow={() => setModalDialog(false)} shareData={sendToShareData} type={2}/>
        </>
    )
}