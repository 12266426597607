import { toast } from "react-toastify";
import API from "../Network/API";

export default function AutoSearch({ data }) {

    const sendrequet=(id)=>{
        API.post(`friend_request?user_id=${id}`).then(response => {
            if(response.data.success === true){
                toast.success("Connect request sent successfully");
                 window.location.reload(false);
            }
        })
    }

    const cancelSendRequet=(id)=>{
        API.put(`update_friend_request/${id}?status=4`).then(response => {
            if(response.data.success === true){
                toast.success(response.data.message);
                window.location.reload(false);

            }
        })
    }
    return (
        <>

            <div className="search-item shadow-sm" id="search-item" style={{ display: 'block' }}>
                <div className="search-list">
                    <div className="recent-chat">
                        <ul className="list-group list-group-flush">


                            {data?.map((value, index) => (

                                <li className="list-group-item ">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="reaction-pp d-flex align-items-center">
                                            <div className="recent-chat-user-img me-2">
                                                <a href={`/contact/${value.user_id}`}>
                                                    <div className="user-picture-container rounded-circle" style={{ background: `url("${value.avatar}")` }} />
                                                </a>
                                            </div>
                                            <div className="name">
                                                <a href={`/contact/${value.user_id}`} className="text-dark">{value.name}</a>
                                                <p style={{fontSize:'12px'}}>{value.email}</p>
                                            </div>
                                        </div>
                                        {value.action_request == 1 ?

                                            <div className="reaction-btn">
                                                <a href={`/messages/thread/${value.user_id}`} className="btn btn-primary btn-width btn-sm ">Massage</a>
                                            </div>
                                            :
                                            value.action_request == 2 ?
                                            <div class="reaction-btn">
                                                <a href="#" onClick={()=>cancelSendRequet(value.user_id)} class="btn btn-outline-primary btn-width btn-sm clickable">
                                                    <i class="fa-solid fa-plus me-2"></i> Cancel Request
                                                </a>
                                            </div>     
                                            :
                                            <div class="reaction-btn">
                                                <a href="#" onClick={()=>sendrequet(value.user_id)} class="btn btn-outline-primary btn-width btn-sm clickable">
                                                    <i class="fa-solid fa-plus me-2"></i> Add Connects
                                                </a>
                                            </div>
                                        }

                                    </div>
                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
            </div>


        </>
    )
}