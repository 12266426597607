
import { createSlice } from '@reduxjs/toolkit';

export const singlePostSlice = createSlice({
    name:'singlePost',
    initialState:{
        loading: true,
        post_data: {},
        error: false,
    },
    reducers:{
        SINGLE_POST_FETCH_START:(state)=>{
            state.loading=true
        },
        SINGLE_POST_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.post_data=action.payload
        },
        SINGLE_POST_FETCH_ERROR:(state)=>{
            state.error=true
        },
        SINGLE_POST_FETCH_COMMENT_ADDON: (state, action) => {
            // console.log(action.payload)
            state.loading = false
            state.post_data=state.post_data.id === action.payload.post_id
            ? state.post_data.last_comments.push(action.payload) && state.post_data
            : state.post_data
        },
        SINGLE_DELETE_COMMENT:(state,action)=>{
           
            state.post_data.last_comments.filter((com)=>com.id !== action.payload.comment_id);
              state.loading = false
              state.post_data=state.post_data.map((pst) =>
              pst.id === action.payload.post_id ? state.post_data : pst
            )
        },
        SINGLE_POST_ADD_REPLY:(state,action)=>{
           
            state.post_data=state.post_data.id === action.payload.post_id
            ? state.post_data.last_comments.map((comment) =>comment.id === action.payload.comment_id?comment.comment_reply.push(action.payload):comment) && state.post_data
            : state.post_data
             
        },

        SINGLE_POST_DELETE_REPLY:(state,action)=>{
            
            //   console.log('rp='+action.payload.comment_reply_id);
            state.post_data.last_comments.map((com)=>com.comment_reply.splice(com.comment_reply.findIndex((fruit) => fruit.id === action.payload.comment_reply_id),1));
              
            state.post_data=state.post_data.id === action.payload.post_id ? state.post_data : state.post_data
            
        },
    }
});

export const { SINGLE_POST_DELETE_REPLY,SINGLE_POST_ADD_REPLY,SINGLE_DELETE_COMMENT,SINGLE_POST_FETCH_COMMENT_ADDON,SINGLE_POST_FETCH_START,SINGLE_POST_FETCH_SUCCESS,SINGLE_POST_FETCH_ERROR }=singlePostSlice.actions;
export default singlePostSlice.reducer;