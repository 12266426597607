import React, { useEffect, useState } from "react";
import API from "../../Network/API";
import ContentLoader from "react-content-loader"
import { Link } from "react-router-dom";
import ShareMeeting from "../Modal/ShareMeeting";
import { toast } from "react-toastify";

export default function MeetingSchedule() {
  const [meetingList, setMeetingList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [demo_data, setDatas] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }]);
  const [progress, setProgress] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [next_page, setNextPage] = useState('/my_schedule_history');
  const [nextPageUrl, setNextPageURL] = useState(null);
  const [lengthData, setDataLength] = useState(0);
  const myArr = JSON.parse(localStorage.getItem('user_data'));
  const [modalDialog, setModalDialog] = useState(false);
  const [sc_meet, setScMeet] = useState(null);
  const toggleModal = (meeting_room) => {
    setScMeet(meeting_room)
    setModalDialog(!modalDialog)
};

  useEffect(() => {
    fetchRepositories()
  }, []);

  function fetchRepositories() {

    setProgress(true)

    API.get(next_page, {
      'offset': meetingList.length
    }).then((response) => {

      const paginator = response.data.data;
      setDataLength(paginator.total)
      if (paginator.meeting_data.length) {
        setMeetingList(paginator.meeting_data)
        setProgress(false)
        setNextPageURL(paginator.next_page_url)
        setPage((page + 1))
      }

    }).catch((error) => {
      setProgress(true)

    });

  }

  function fetchRepositoriesNext() {

    setLoading(true)

    API.get(next_page + '?page=' + page, {
      'offset': meetingList.length
    }).then((response) => {
      setLoading(false)
      const paginator = response.data.data;
      setDataLength(paginator.total)
      if (paginator.meeting_data.length) {
        setMeetingList([...meetingList, ...paginator.meeting_data])
        setNextPageURL(paginator.next_page_url)
        setPage((page + 1))
        setIsFetching(false)
      }
    }).catch((error) => {
      setLoading(false)
    });

  }

  function scheduleStart(meeting_id){
    API.get('/meeting/'+meeting_id).then(res => {
        window.location.href = "/meeting/"+meeting_id;
   }).catch((e)=>{
    console.log(e);
   });
  }

  // const meeting_delete = (m_id) => {
  //   var result = window.confirm("Are you sure want to delete?");
  //   if (result == true) {
  //     setLoading(true)
  //     API.delete("/meeting_delete/" + m_id).then((response) => {
  //       setLoading(false)
  //       if (response.data.success === true) {
  //         toast.success('delete successfully');
  //       }
  //     });
  //   }
    
  // }

  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="col-md-10">
          <div className="friend_list-page">
            <div className="row">
              <div className="friend_list-heading">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="friend_list-heading-text">
                    <h4>Schedule Meetings</h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-2">

              <div className="col-md-12">
                <div className="bg-white shadow-sm single-friend-list ">

                  
                      {progress && demo_data?.map((post, i) => (
                        <li className="list-group-item">
                          <div className="d-md-flex justify-content-between align-items-center d-sm-block">

                            <ContentLoader
                              speed={2}
                              width={400}
                              height={80}
                              viewBox="0 0 500 80"
                              backgroundColor="#dfdddd"
                              foregroundColor="#ebebeb"

                            >
                              <circle cx="36" cy="55" r="17" />
                              <rect x="81" y="43" rx="0" ry="0" width="327" height="8" />
                              <rect x="82" y="57" rx="0" ry="0" width="271" height="7" />
                              <circle cx="52" cy="55" r="17" />
                            </ContentLoader>
                          </div>
                        </li>
                      ))}

                      <table className="table table-striped align-middle">

                      <thead>
                        <tr>
                          <th scope="col">Users</th>
                          <th scope="col">Title</th>
                          <th scope="col">Date</th>
                          <th scope="col">Time</th>
                          <th scope="col">Timezone</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>


                      <tbody>

                      {!progress && meetingList?.map((meeting, index) => (

                      
                        <tr>
                              <th scope="row">
                                <div className="meeting-user-img">
                                  <ul className="pe-2">
                                  
                                    {
                                      meeting.room_history.length>0?
                                      meeting.room_history?.slice(0, 2)?.map((room_user, k) => (
                                        
                                        <li key={k}>
                                          <a href={`/contact/${room_user.user_id}`}><div className="user-picture-container rounded-circle meeting_history-user" style={{ background: `url(${room_user.avatar})` }} /></a>
                                        </li>
                                      ))
                                      :
                                      <li>
                                      <a href="#">
                                        <div className="user-text bg-secondary rounded-circle meeting_history-text">
                                          <span className=" ">{myArr.name?.slice(0,1)}</span>
                                        </div>
                                      </a>
                                    </li>
                                    
                                    }
                                  </ul>
                                </div>
                                </th>

                            <td>
                            <div className="name">
                              <a href="#">{meeting.room_title}</a>
                            </div>
                            </td>


                            <td>
                            <div className="meeting-dateTime">
                              <p>{new Date(meeting.start_date).toDateString()}</p>
                            </div>
                            </td>

                            <td>
                            <div className="meeting-dateTime">
                              <p>{new Date(meeting.start_date).toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12: true })}</p>
                            </div>
                            </td>

                            <td>
                            <div className="meeting-dateTime">
                              <p>{meeting.time_zone}</p>
                            </div>
                            </td>

                            <td>
                            <div className="global-dropdown">
                            <div className="dropdown">
                                <button className="dropdown-toggle dropdown-bg-transparent" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical" /></button>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item align-items-center" onClick={()=>scheduleStart(meeting.room_name)}>Start Meeting</a></li>
                                    <li><a className="dropdown-item  align-items-center" href={`https://connectsapp.com/meeting-waiting/${meeting.room_name}`}>Preview</a></li>
                                    <li><a className="dropdown-item  align-items-center clickable" onClick={()=>toggleModal(meeting)}>Share</a></li>
                                    {/* <li><a className="dropdown-item  align-items-center clickable" onClick={()=>meeting_delete(meeting.id)}>Delete</a></li> */}
                                    
                                    {/* <li><a className="dropdown-item  align-items-center" href={`https://connectsweb.com/meeting-waiting/${meeting.room_name}`}>Edit</a></li> */}
                                    {/* <li><a className="dropdown-item  align-items-center" href={`https://connectsweb.com/meeting-waiting/${meeting.room_name}`}>Delete</a></li> */}
                                </ul>
                            </div>
                            </div>
                            </td>
                        </tr>

                      ))}
                        </tbody>   
                      </table>


                  {nextPageUrl != null ?
                    <div className={'text-center'} style={{ marginBottom: '10px' }}>

                      <button className={'btn btn-lg btn-primary'} onClick={fetchRepositoriesNext}>Load More {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm ml-5"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        <span></span>
                      )}</button>
                    </div>
                    : ''
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareMeeting isModalVisible={modalDialog} meetingRoom={sc_meet} handleShowB={() => setModalDialog(false)}/>
    </>
  )
}