import { createSlice } from '@reduxjs/toolkit';

export const messageSlice = createSlice({
    name:'inbox',
    initialState:{
        loading: true,
        data: {},
        users: [],
        error: false,
    },
    reducers:{
        MESSAGE_FETCH_START:(state)=>{
            state.loading=true
        },
        MESSAGE_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.users=action.payload.data
        },
        MESSAGE_FETCH_LOAD_MORE_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=[ ...state.data, ...action.payload]
            state.users=[ ...state.users, ...action.payload.data]
        },
        MESSAGE_FETCH_ECHO:(state,action)=>{
            const objIndex = state.users.findIndex((obj => obj.to_id == action.payload.to_id));
            // console.log(objIndex)
            state.loading=false
            if(objIndex<-1){
                state.data=[ ...state.data, action.payload]
                state.users=[ ...state.users, action.payload]
            }else{
                state.users[objIndex]=action.payload
            }
            
        },
        MESSAGE_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { MESSAGE_FETCH_START,MESSAGE_FETCH_ERROR,MESSAGE_FETCH_SUCCESS,MESSAGE_FETCH_LOAD_MORE_SUCCESS,MESSAGE_FETCH_ECHO }=messageSlice.actions;
export default messageSlice.reducer;