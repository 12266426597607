
import { createSlice } from '@reduxjs/toolkit';

export const inboxSlice = createSlice({
    name:'message',
    initialState:{
        loading: true,
        data: {},
        messsage: [],
        error: false,
    },
    reducers:{
        INBOX_FETCH_START:(state)=>{
            state.loading=true
        },
        INBOX_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.messsage=action.payload.conversations.slice().reverse()
        },
        RECEIVE_MESSAGE_LOADING:(state,action)=>{
            state.loading=false
            state.data=action.payload
            state.messsage=[...action.payload.conversations.slice().reverse(),...state.messsage]
        },
        DELETE_MESSAGE:(state,action)=>{
            const filterPosts = state.messsage.filter(
                (messs) => messs.id !== action.payload
              );
            
            state.loading = false
            state.messsage=filterPosts
        },
        DELETE_TEMP_MESSAGE:(state,action)=>{
            const filterPosts = state.messsage.filter(
                (messs) => messs.temp_id !== action.payload
            );

            state.loading = false
            state.messsage=filterPosts
        },
        
        RECEIVE_SOCKET_GROUP_MESSAGE:(state,action)=>{   
         state.messsage.forEach((message,index) => {
            if (message.to_id == action.payload.to_id ) {
                if (index == state.messsage.length - 1){
                    state.messsage=state.messsage.concat(action.payload)
                }
                
            }
          });
          
        },
        RECEIVE_SOCKET_MESSAGE:(state,action)=>{
            // const objIndex = state.users.findIndex((obj => obj.to_id == action.payload.to_id));
        // const mess=state.messsage.filter((a)=>a.to_id==action.payload.to_id)
        //     state.messsage=mess.concat(action.payload)
      
      state.messsage=state.messsage.concat(action.payload)
           
    //    state.messsage.forEach((message,index) => {
    //         if (message.from_id == action.payload.from_id ) {
    //             if (index == state.messsage.length - 1){
                    
    //             }

    //             state.messsage=state.messsage.concat(action.payload)
    //         }
    //       });
          
        },
        ADD_MESSAGE_SUCCESS:(state,action)=>{
            state.loading=false
            state.messsage=state.messsage.concat(action.payload)
        },
        INBOX_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { INBOX_FETCH_START,INBOX_FETCH_ERROR,DELETE_MESSAGE,RECEIVE_SOCKET_GROUP_MESSAGE,INBOX_FETCH_SUCCESS,RECEIVE_MESSAGE_LOADING,ADD_MESSAGE_SUCCESS,RECEIVE_SOCKET_MESSAGE,DELETE_TEMP_MESSAGE }=inboxSlice.actions;
export default inboxSlice.reducer;