
import { createSlice } from '@reduxjs/toolkit';

export const roleSlice = createSlice({
    name:'role',
    initialState:{
        loading: true,
        role_id: 0,
        error: false,
    },
    reducers:{
        ROLE_FETCH_START:(state)=>{
            state.loading=true
        },
        ADD_ROLE_SUCCESS:(state,action)=>{
            
            state.loading=false
            state.role_id=action.payload
        },
        ROLE_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { ROLE_FETCH_START,ADD_ROLE_SUCCESS,ROLE_FETCH_ERROR }=roleSlice.actions;
export default roleSlice.reducer;