
import HomeMeeting from "../HomeMeeting";
import Carousel from 'react-bootstrap/Carousel';
import React, {Component, Fragment, useEffect, useState, memo} from "react";
import API from "../../Network/API";
import RecentChat from "../RecentChat";
import ConnectSuggest from "../ConnectSuggest";
import ContentLoader from "react-content-loader"

export default function RightSide(props) {
    const [WhatsNew, setWhatsNew] = useState([]);
    const [data_read, setData_read] = useState(false);
    const [progress, setProgress] = useState(false);

    useEffect(() => {
        setProgress(true)
        API.get(`/getWhatsNewFeatures`).then(response => {
            setWhatsNew(response.data.data)
            setProgress(false)
        })

    }, []);
    return(
        <Fragment>
            <div className="app-right-sidebar sidebar">
                <div className="sidebar-scroll">
                    <div className="widget-area">
                        <div className="widget widget-banner mb-4">
                    <h4>What’s new</h4>
                    <div className="widget-banner">
                        {progress ?
                            <ContentLoader
                                speed={2}
                                width={400}
                                height={142}
                                viewBox="0 0 400 142"
                                backgroundColor="#eeecec"
                                foregroundColor="#eae6e6"
                                {...props}
                            >
                                <rect x="0" y="0" rx="20" ry="20" width="300" height="142" />
                            </ContentLoader>
                            :

                        <Carousel fade interval={3500} controls={false} indicators={false}>
                            {!progress && WhatsNew.map((value, i) => (
                                <Carousel.Item key={i}>
                                    <div key={i} className="post-images" data-bs-toggle="modal" data-bs-target={`#WhatNewModal${i}`}>
                                        <img
                                            src={value.image}
                                            className="rounded-3 img-fluid" alt="image"/>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>

                        }
                    </div>
                </div>

                <HomeMeeting/>
            
                    

                <div className="widget widget-who-following mb-4">
                  <ConnectSuggest/>
                </div>
            </div>
                </div>
                </div>
                </Fragment>
    )
}