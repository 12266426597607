import API from "../Network/API";
import { POST_FETCH_ERROR, POST_FETCH_SUCCESS,POST_FETCH_SUCCESS_LOAD } from "../Reducer/postReducer";
import { USER_FETCH_ERROR, USER_FETCH_SUCCESS } from "../Reducer/userReducer";
import { Notify_FETCH_ERROR, Notify_FETCH_SUCCESS } from "../Reducer/notifyReducer";
import { MESSAGE_FETCH_SUCCESS, MESSAGE_FETCH_ERROR } from "../Reducer/messageReducer";
import { INBOX_FETCH_SUCCESS, INBOX_FETCH_ERROR } from "../Reducer/userInboxReducer";
import { INBOX_ATTACHMENT_FETCH_SUCCESS, INBOX_ATTACHMENT_FETCH_ERROR } from "../Reducer/inboxAttachment";

const limit = (count) => `page=${count}`;

const Articles = {
    all: (page, dispatch) =>
        API.get(`/post_syncs?page=${page}`)
        .then(response => {
            dispatch(POST_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(POST_FETCH_ERROR());
        }),

        loadMore: (page, dispatch) =>
        API.get(`/post_syncs?page=${page}`)
        .then(response => {
            dispatch(POST_FETCH_SUCCESS_LOAD(response.data.data));
        }).catch((error) => {
            dispatch(POST_FETCH_ERROR());
        })
}


const Comments = {
    create: (slug, comment) =>
        API.post(`/articles/${slug}/comments`, { comment }),
    delete: (slug, commentId) =>
        API.delete(`/articles/${slug}/comments/${commentId}`),
    forArticle: slug =>
        API.get(`/articles/${slug}/comments`)
};


const Notification = {
    all: (page, dispatch) =>
        API.get(`/notifications?${limit(20, page)}`).then(response => {
            dispatch(Notify_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(Notify_FETCH_ERROR());
        }),

    latest: (dispatch) =>
        API.get(`/notifications`).then(response => {
            dispatch(Notify_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(Notify_FETCH_ERROR());
        }),

    readAll: () =>
        API.post('/notification-read-all'),
}

const Auth = {
    current: (dispatch) =>
        API.get(`/user_sync`).then(response => {
            dispatch(USER_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(USER_FETCH_ERROR());
        }),
    login: (email, password) =>
        API.post('/login', { user: { email, password } }),
    register: (username, email, password) =>
        API.post('/users', { user: { username, email, password } }),
    save: user =>
        API.put('/user', { user })
};

const User = {
    getData: (dispatch) =>
        API.get(`/user_sync`).then(response => {
            dispatch(USER_FETCH_SUCCESS(response.data.data));
        }).catch((error) => {
            dispatch(USER_FETCH_ERROR());
        })
};

const Message = {
    chatList: (dispatch) =>
        API.get(`/latestConversations`)
            .then(response => {
                dispatch(MESSAGE_FETCH_SUCCESS(response.data));
            }).catch((error) => {
                dispatch(MESSAGE_FETCH_ERROR());
            }),

    inbox: (dispatch, id) =>
        API.get(`/users/${id}/conversation`)
            .then(response => {
                dispatch(INBOX_FETCH_SUCCESS(response.data.data));
            }).catch((error) => {
                dispatch(INBOX_FETCH_ERROR());
            }),

    group_inbox: (dispatch, id) =>
        API.get(`/users/${id}/conversation?is_group=1`)
            .then(response => {
                dispatch(INBOX_FETCH_SUCCESS(response.data.data));
            }).catch((error) => {
                dispatch(INBOX_FETCH_ERROR());
            }),

    attachment: (dispatch, id) => {
        API.get(`/friend_file_sharing/${id}`).then(response => {
            dispatch(INBOX_ATTACHMENT_FETCH_SUCCESS(response.data));
        }).catch((error) => {
            dispatch(INBOX_ATTACHMENT_FETCH_ERROR());
        })
    }
}

export default {
    Auth,
    Articles,
    Notification,
    Comments,
    User,
    Message,
};