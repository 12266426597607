export default function DivPlaceholder() {
    return (
        <>

            <div className="news-feed news-feed-post  mb-3">
                <div className="placeload">
                    <div className="header">
                        <div className="img loads"></div>
                        <div className="header-content">
                            <div className="content-shape loads"></div>
                            <div className="content-shape loads"></div>
                        </div>
                    </div>
                    <div className="image-placeholder loads"></div>
                    <div className="placeholder-footer">
                        <div className="footer-block">
                            <div className="content-shape loads"></div>
                            <div className="content-shape loads"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}