import React, {useEffect, useState} from "react";
import MessageNav from "./MessageNav";
import BlankInbox from "./BlankInbox";
import { useSelector } from "react-redux";

export default function DefaultMessageBox() {
    const [deviceType, setDeviceType] = useState("");
  
    useEffect(() => {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                    navigator.userAgent
                )
            ) {
                setDeviceType("Mobile");
            } else {
                setDeviceType("Desktop");
            }
    
        },

        []);

  
    return (
        <>
            <div className="row">
                {deviceType === 'Desktop' ?
                    <>
                        <div className="col-md-3 d-none d-sm-block">
                            <MessageNav/>
                        </div>
                        <BlankInbox/>
                    </> :
                    <div className="col-md-12">
                        <MessageNav/>
                    </div>
                }
            </div>
            </>
    )
}