import React, { useEffect } from 'react';
import Nav from "./Component/Partials/Nav";
import LeftSide from "./Component/Partials/LeftSide";
import RightSide from "./Component/Partials/RightSide";
import MobileNav from "./Component/Partials/MobileNav";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Agent from './Network/Agent';
import { USER_SELF_FETCH_SUCCESS } from './Reducer/UserSelfReducer';

const App = () => {
    const location = useLocation();
    let { id } = useParams();
    let { room } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(USER_SELF_FETCH_SUCCESS(JSON.parse(localStorage.getItem("user_data"))))
        Agent.User.getData(dispatch)
        Agent.Notification.latest(dispatch)
        Agent.Message.chatList(dispatch)

    }, []);

    const user = useSelector((state) => state.userReducer?.data)
    if (user) {
        localStorage.setItem("profile_img", user.avatar)
        localStorage.setItem("profile_name", user.name)
        localStorage.setItem("user_data", JSON.stringify(user))
        dispatch(USER_SELF_FETCH_SUCCESS(user))
    }

    return (

        <>

            <div className="main-content-wrapper d-flex flex-column">
                <Nav />
                {location.pathname == `/meeting-details/${room}` ?
                    <Outlet />
                    :
                    <>
                        <LeftSide />
                        {location.pathname === '/all-story' || location.pathname === '/videos' || location.pathname === `/video/${id}` ? '' :

                            <RightSide />

                        }
                        <div
                            className={`content-page-box-area  ${location.pathname === '/all-story' || location.pathname === '/videos' || location.pathname === `/video/${id}` ? 'content-box-margin-l' : 'content-box-margin-lr'}`}>
                            <div className="container-fluid">

                                <Outlet />

                            </div>
                        </div>
                    </>
                }
            </div>


            {/*mobile nav start*/}
            <div className="mobile-bottom-nav d-xl-none fixed-bottom shadow-lg mobile-menu">
                <MobileNav />
            </div>


        </>
    );
}

export default App;

