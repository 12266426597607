import React, {Component, Fragment, useEffect, useState, memo} from "react";
import API from "../Network/API";
import {Link} from "react-router-dom";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export default function FriendRequestAccept() {
    const [friendList, setFriendList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [div_id, setDivId] = useState(0);
    useEffect(() => {
        _getData();
    }, []);

    function _getData() {
        try {
            setLoading(true);
            API.get(`/get_pending_friend_request`).then(response => {
                setLoading(false);
                setFriendList(response.data.data);
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    function _receivedFriendRequest(id, status) {
        setLoading(true);
        API.put(`/update_friend_request/${id}`, {
            status: status
        }).then((response) => {
            setDivId(id)
            if (response.data.success === true) {
                toast.success("Connect Request accepted");
                _getData();
            }
        }).catch((error) => {
            console.log(error.response)
        });
    }

    return (
        <Fragment>
            <div className="option-item ">
                <div className="dropdown friend-requests-nav-item">
                    <Link to={''} className="dropdown-bs-toggle" role="button"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div className="friend-requests-count">
                            <i className="fa-solid fa-user"/>
                            {friendList.length > 0 ? <span>{friendList.length}</span> : "" }
                        </div>
                    </Link>
                    <div className="dropdown-menu">
                        <div
                            className="friend-requests-header d-flex justify-content-between align-items-center">
                            <h3>Friend Requests</h3>
                            <i className="fa-solid fa-ellipsis"/>
                        </div>
                        <div className="friend-requests-body">
                            {friendList?.map((value, index) => (
                                <div className="item d-flex align-items-center">
                                    <div className="figure">
                                        <a href="javascript:void(0)">
                                            <div className="user-picture-container rounded-circle "
                                                 style={{background: `url("${value.avatar}")`}}/>
                                        </a>
                                    </div>
                                    <div className="content d-flex justify-content-between align-items-center">
                                        <div className="text">
                                            <h4><a href="javascript:void(0)">{value.name}</a></h4>
                                            <span>{value.request_time}</span>
                                        </div>
                                        <div className="btn-box d-flex align-items-center">
                                            <button onClick={()=>{ _receivedFriendRequest(value.user_id,2) }}  type="button"
                                                    className="delete-btn d-inline-block me-2 friend-requests-btn"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
                                                <i className="fa-solid fa-xmark"/>
                                            </button>
                                            <button onClick={()=>{ _receivedFriendRequest(value.user_id,1) }} href="javascript:void(0)" type="button"
                                                    className="confirm-btn d-inline-block friend-requests-btn"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Confirm">
                                                <i className="fa-solid fa-check"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                            }
                            {friendList.length>0?
                            <div className="view-all-requests-btn text-center ">
                                <a href="javascript:void(0)" className="btn default-btn w-100">View All
                                    Requests</a>
                            </div>
                                : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}