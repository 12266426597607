import React from "react";
import {Link} from "react-router-dom";

export default function FriendMessage({value}) {
    return(
        <Link to={`/messages/${value.is_group==1?'group':'thread'}/${value.to_id}`}>
            <div className="item d-flex justify-content-between align-items-center">
                <div className="figure">
                    <a href="javascript:void(0)">
                        <div className="user-picture-container rounded-circle " style={{background: `url(${value.image})`}} />
                    </a>
                </div>
                <div className="text">
                    <h4><a href="javascript:void(0)">{value.name} </a></h4>
                    <p className={`${value.unread_count>0? 'text-primary':''}`}>{value.message?.length > 28 ? `${value.message.substring(0, 27)}...` : value.message}</p>
                </div>
            </div>
        </Link>
    )
}