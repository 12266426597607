
import MyProfile from "./MyProfile";
import React from "react";

export default function MainSectionProfile() {
    return(
        <>
            <div className="row d-flex justify-content-center">
                <div className="col-md-10">
        <MyProfile/>
                </div>
                </div>
        </>
    )
}