import React,{Component} from 'react';
import { Spinner, Button } from 'react-bootstrap';


class Load extends Component {
    render() {
        return (
            <div className="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3">
                <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                    <div className="stage">
                        <div className="dot-typing">Meeting Loading...</div>
                        <Spinner animation="grow" variant="dark"/> 

                    </div>
                </div>
            </div>
        );
    }
}

export default Load;