

import { createSlice } from '@reduxjs/toolkit';

export const inboxAttachSlice = createSlice({
    name:'message_attachment',
    initialState:{
        loading: true,
        data: {},
        error: false,
    },
    reducers:{
        INBOX_ATTACHMENT_FETCH_START:(state)=>{
            state.loading=true
        },
        INBOX_ATTACHMENT_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
        },
        INBOX_ATTACHMENT_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { INBOX_ATTACHMENT_FETCH_START,INBOX_ATTACHMENT_FETCH_ERROR,INBOX_ATTACHMENT_FETCH_SUCCESS }=inboxAttachSlice.actions;
export default inboxAttachSlice.reducer;