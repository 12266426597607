import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import API from "../../Network/API";
import { Link, useNavigate } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import Agent from "../../Network/Agent";
import { useDispatch } from "react-redux";

export default function AddGroupModal(props) {

    const [chatlist_data, setChatListData] = useState([]);
    const [progress, setProgress] = useState(false);
    const [show_create_group, setCreateGroup] = useState(false);
    const [show_prev, setPrv] = useState(false);
    const [show_next, setNextButton] = useState(false);
    const [show_submit, setSubmitButton] = useState(false);
    const [user_id, setUserId] = useState([]);
    const [count_users, setCoutUser] = useState(0);
    const [name, setName] = useState("");
    const [details, setDetails] = useState("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        setProgress(true)
        API.get('search_friend').then((response) => {
            if (response.data.success === true) {
                setProgress(false)
                setChatListData(response.data.data)
            } else {
                setProgress(false)
            }
        }).catch((error) => {
            setProgress(false)
        });

    }, []);

    const fetch_search = (value) => {
        setProgress(true)
        if (value === "" || value === null) {
            setProgress(false)
            return false
        } else {
            API.get('search_friend?q=' + value).then((response) => {
                if (response.data.success === true) {
                    setProgress(false)
                    setChatListData(response.data.data)
                } else {
                    setProgress(false)
                }
            }).catch((error) => {
                setProgress(false)
            });
        }

    }

    const NextPage = () => {
        setPrv(true);
        setCreateGroup(true);
        setSubmitButton(true);
    }

    const PrvPage = () => {
        setPrv(false);
        setCreateGroup(false);
        setSubmitButton(false);
        setNextButton(true);
    }

    const AddUserId = (event) => {
        if (event.target.checked) {
            if (user_id.indexOf(parseInt(event.target.value)) === -1) {
                user_id.push(parseInt(event.target.value))
            }
        } else {
            removeID(parseInt(event.target.value))
        }
        setCoutUser(user_id.length)
    }

    const removeID = (url) => {
        const index = user_id.indexOf(url);
        if (index > -1) { // only splice array when item is found
            user_id.splice(index, 1); // 2nd parameter means remove one item only
        }
        setUserId(user_id)
    }

    const checkedState = (id) => {
        return user_id.find((element) => {
            // console.log(element)
            return element === id;
        })
        // const index = user_id.indexOf(id);
        // if (index > -1) { // only splice array when item is found
        //  return true;
        // }
        //  return false;
    }

    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    const submitGroup = () => {
        setLoading(true)
        let formData = new FormData();
        if (file != null) {
            formData.append('photo', file, file.name)
        }
        formData.append("privacy", 1);
        formData.append("group_type", 1);
        formData.append("name", name);
        formData.append("description", details);

        user_id.map((value, index) => {
            formData.append("users[]", value);
        })

        API.post("/chat_groups", formData, {
            onUploadProgress: progressEvent => {
                console.log("Progress: " + progressEvent.loaded / progressEvent.total);
                setProgress(progressEvent.loaded / progressEvent.total);
            }
        })
            .then((response) => {
                setLoading(false)
                if (response.success == true) {
                    toast.success("group create successfully");
                }
                 setUserId([])
                 const tt = response.data;
                 Agent.Message.group_inbox(dispatch, tt.to_id)
                 Agent.Message.chatList(dispatch)
                props.handleShowB()
                props.callbackData(true)
                navigate("/messages/group/" + tt.to_id);
                // window.location.reload();
            })
            .catch((err) => {
                
                toast.error("Something was wrong");
                props.handleShowB()
                props.callbackData(true)
                setLoading(false)
            });
    }
    return (
        <>
            <Modal
                isOpen={props.isModalVisible}
                size=""
                toggle={props.handleShowB}
                className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
            >
                <ModalHeader toggle={props.handleShowB} id={'staticBackdropLabel'}>
                    Add Contacts

                </ModalHeader>

                <ModalBody >
                    {!show_create_group ?
                        <>
                            <div className="message-people-srch ">
                                <div className="messages-search-box">
                                    <form>
                                        <input type="text" onChange={(e) => fetch_search(e.target.value)} className="input-search shadow" placeholder="Search contacts..." />
                                        <button type="button">
                                            <i className="fa-solid fa-magnifying-glass" />
                                        </button>
                                    </form>
                                </div>
                            </div>

                            <div className="mesg-peple">
                                <ul className="messages-list">
                                    {progress && [1, 2, 3, 4, 5]?.map((post, index) => (
                                        <div key={index}>
                                            <li>

                                                <div className="usr-msg-details d-flex align-items-center">
                                                    <div className="usr-ms-img flex-grow-1">
                                                        <ContentLoader
                                                            speed={3}
                                                            width={250}
                                                            height={80}
                                                            viewBox="0 0 250 80"
                                                            backgroundColor="#dfdddd"
                                                            foregroundColor="#ebebeb"

                                                        >
                                                            <circle cx="53" cy="57" r="24" className="user-picture-container rounded-circle" />
                                                            <rect x="81" y="43" rx="0" ry="0" width="327" height="8" />
                                                            <rect x="82" y="57" rx="0" ry="0" width="271" height="7" />
                                                        </ContentLoader>

                                                    </div>
                                                </div>
                                            </li>
                                        </div>
                                    ))}

                                    {!progress && chatlist_data?.length > 0 ?
                                        chatlist_data?.map((value, index) => (
                                            <li className>
                                                <div className="usr-msg-details d-flex align-items-center">
                                                    <div className="usr-ms-img flex-grow-1">
                                                        <div className="d-flex align-items-center">
                                                            <div className="user-picture-container rounded-circle" style={{ background: `url(${value.avatar})` }} />
                                                            <div className="usr-mg-info">
                                                                <h3>{value.name} </h3>
                                                                <span className="d-block text-muted">{value.bio?.length > 23 ? `${value.bio.substring(0, 22)}...` : value.bio} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="msg-seen-time d-flex align-items-center">
                                                        <Link to={`/messages/thread/${value.user_id}`} onClick={props.handleShowB} className="group-msg-icon">
                                                            <i className="far fa-comment-alt" />
                                                        </Link>
                                                        <div className="form-check">
                                                            <input defaultChecked={checkedState(value.user_id)} className="form-check-input form-check-input-lg" type="checkbox" onClick={(e) => AddUserId(e)} value={value.user_id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )) : ''}

                                </ul>
                            </div>
                        </>
                        :
                        <>
                            <div className="mb-3">
                                <div className="groupPhotos">
                                    <label className="fileContainer">
                                        <i className="fas fa-camera" />
                                        <span className="d-block">Upload Profile Photos</span>
                                        <input type="file" onChange={handleFileChange} />
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Grouptitle" className="form-label">Title</label>
                                <input type="text" onChange={(e) => setName(e.target.value)} className="form-control border-top-0 border-start-0 border-end-0 border-bottom-1" id="Grouptitle" placeholder="Enter Group Name" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="GroupDiscription" className="form-label">Discription</label>
                                <input type="text" onChange={(e) => setDetails(e.target.value)} className="form-control border-top-0 border-start-0 border-end-0 border-bottom-1" id="GroupDiscription" placeholder="Write somethings about this group" />
                            </div>
                        </>
                    }

                </ModalBody>

                <ModalFooter className={'d-flex justify-content-between border-top-0'}>
                    <p className="m-0 p-0 text-muted">
                        Select {count_users} Contacts
                    </p>

                    <div className="groupInvitation-btn">
                        {show_prev ? <button type="button" className="btn" onClick={() => PrvPage()}>Previous</button> : ''}


                        {
                            show_submit ? <button type="button" className="btn btn-primary" onClick={() => submitGroup()}>Submit</button> : <button type="button" className="btn btn-primary" onClick={NextPage}>Next</button>
                        }
                    </div>

                </ModalFooter>
            </Modal>
        </>
    )

}