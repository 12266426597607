import API from "../Network/API";
import {ACTION_TYPES} from "../Network/postActionTypes";
import React, {useEffect, useReducer, useState} from "react";
import {INITIAL_STATE, NotificationReducer} from "../Reducer/NotificationReducer";
import NotificationList from "../Props/NotificationList";
import ContentLoader from "react-content-loader"

export default function Notification() {
    const [page, setPage] = useState(1);
    const [state, dispatch] = useReducer(NotificationReducer, INITIAL_STATE);
    const [progress, setProgress] = useState(false);
    useEffect(() => {

        dispatch({type: ACTION_TYPES.FETCH_START});
        API.get(`/notifications-all`).then(response => {
            dispatch({type: ACTION_TYPES.FETCH_SUCCESS, payload: response.data.data});
            if (response.data.data.notification.length>0) {
                setPage((page+1))
            }
        }).catch((error) => {
            dispatch({type: ACTION_TYPES.FETCH_ERROR});
        });



    }, []);

    function fetchNext() {
        setProgress(true)
        API.get(`/notifications-all?page=${page}`).then(response => {
            dispatch({type: ACTION_TYPES.FETCH_SUCCESS_LOAD, payload: response.data.data});
            if (response.data.data.notification.length>0) {
                setPage((page+1))
            }
            setProgress(false)
        }).catch((error) => {
            dispatch({type: ACTION_TYPES.FETCH_ERROR});
            setProgress(false)
        });

    }
    return (
        <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-10">
                <div className="all-notification-page">
                    <div className="row">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="notification-heading-text">
                                <h5 className="m-0">Notifications</h5>
                            </div>
                            <div className="all-notification-buttons ms-auto d-flex">
                                <div className="d-grid gap-2 d-md-block">
                                    <button className="btn btn-notification-all rounded-pill px-3" type="button">All</button>
                                    <button className="btn btn-outline-secondary rounded-pill" type="button">Unread</button>
                                </div>
                                {/* <div className="global-dropdown ms-2">
                                    <div className="dropdown">
                                        <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa-solid fa-ellipsis-vertical" /></button>
                                        <ul className="dropdown-menu" style={{}}>
                                            <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="fa-solid fa-pen-to-square" />
                                                Edit Post</a></li>
                                            <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="fa-solid fa-eye-slash" /> Hide
                                                Post</a></li>
                                            <li><a className="dropdown-item d-flex align-items-center" href="#"><i className="fa-solid fa-trash-can" /> Delete
                                                Post</a></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row g-2">
                        <div className="col-md-12">
                            <div className="bg-white shadow-sm py-3">
                                {/* <div className="fw-bold pb-2 pt-2 px-3">All Notification</div> */}
                                <div className="recent-chat">
                                    <ul className="list-group list-group-flush">
                                        {state.loading && [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}]?.map((post, i) => (
                                        <ContentLoader
                                            speed={2}
                                            width={400}
                                            height={90}
                                            viewBox="0 0 400 90"
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                        >
                                            <circle cx="53" cy="57" r="24" />
                                            <rect x="81" y="43" rx="0" ry="0" width="400" height="8" />
                                            <rect x="82" y="57" rx="0" ry="0" width="271" height="7" />
                                            <circle cx="67" cy="74" r="13" />
                                        </ContentLoader>
                                            ))}

                                        <NotificationList notification={state.notification_data}/>
                                    </ul>
                                </div>
                                {state.data.next_page_url!=null?
                                <a  onClick={()=>fetchNext()} className="fw-bold text-secondary text-center pt-3 d-block clickable">Load more {progress ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-5"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    <span></span>
                                )}</a>
                                    :''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}