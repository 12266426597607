import {ACTION_TYPES} from "../Network/postActionTypes";

export const INITIAL_STATE = {
    loading: false,
    progress: false,
    data: {},
    notification_data: [],
    error: false,
};

export const NotificationReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_START:
            return {
                loading: true,
                progress: false,
                error: false,
                data: {},
                notification_data: [],
            };
        case ACTION_TYPES.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                progress: false,
                data: action.payload,
                notification_data: action.payload.notification,
            };
        case ACTION_TYPES.FETCH_SUCCESS_LOAD:
            if (!state.notification_data) {
                state.notification_data = action.payload.notification;
            }
            return {
                ...state,
                loading: false,
                progress: false,
                data: action.payload,
                notification_data: [ ...state.notification_data, ...action.payload.notification],
            };
        case ACTION_TYPES.FETCH_ERROR:
            return {
                error: true,
                loading: false,
                progress: false,
                data: {},
                notification_data: [],
            };
        default:
            return state;
    }
};