import API from "../../Network/API";
import Echo from "laravel-echo";
import React, {Component, Fragment, useEffect, useRef, useState, memo} from "react";

export default function BlankInbox() {
    return (<>
        <div className="col-md-9 margin-top-minex">
        <div className="empty-state no-selection">
            <img src={'assets/img/da39d35.svg'}/>
            <h2 className="font-accent">Select a Conversation</h2>
            <small>Try
            selecting a conversation or searching for someone specific.</small></div>
        </div>
    </>)
}
