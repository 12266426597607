
import { createSlice } from '@reduxjs/toolkit';

export const userSelfSlice = createSlice({
    name:'self',
    initialState:{
        loading: false,
        data: {},
        error: false,
    },
    reducers:{
        USER_SELF_FETCH_START:(state)=>{
            state.loading=true
        },
        USER_SELF_FETCH_SUCCESS:(state,action)=>{
            state.loading=false
            state.data=action.payload
        },
        USER_SELF_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { USER_SELF_FETCH_START,USER_SELF_FETCH_SUCCESS,USER_SELF_FETCH_ERROR }=userSelfSlice.actions;
export default userSelfSlice.reducer;