import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import API from "../../Network/API";



export default function ShareMeeting(props) {
    const [data, setData] = useState(null);
  const [name, setName] = useState(null);
  const [isLoading, setLoad] = useState(false);
  const [user_select_data, setUserSelectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user_id, setUserId] = useState([]);
  const [meeting_link, setMeetingLink] = useState(null);

    
    const myArr = JSON.parse(localStorage.getItem('user_data'));
    // useEffect(() => {
    //     console.log(props.meetingRoom);
    //   }, []);
    function myFunction() {
        // Get the text field
        var copyText = document.getElementsByClassName("myInput");
        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText[0].textContent+'\n\n'+copyText[1].textContent+'\n'+copyText[2].textContent+'\n'+copyText[3].textContent+'\n\n'+copyText[4].textContent+'\n'+copyText[5].textContent+'\n'+copyText[6].textContent);
        // Alert the copied text
      }

      const clear_search=()=>{
        setLoad(false)
      }
    
      function search_friend(e) {
        setName(e)
        if (e!=="" || e!==null){
          setLoad(true)
          API.get(`/search_friend?q=${e}`).then((response) => {
            setLoad(true)
            setData(response.data.data)
          }).catch((error) => {
            setLoad(false)
          });
        }
      }
    
      const AddUserId = (event) => {
        if (event.target.checked) {
          if (user_id.indexOf(parseInt(event.target.value)) === -1) {
            user_id.push(parseInt(event.target.value))
            user_select_data.push({
              'id':parseInt(event.target.value),
              'name':event.target.title,
            })
          }
        } else {
         removeID(parseInt(event.target.value))
        }
        setLoad(false)
      }
    
    const removeUser=(sl,u_id)=>{
      // const sdad=user_select_data
      // const index = sdad.findIndex(key => key.id === u_id)
      // sdad.splice(index,1)
       setUserSelectData(user_select_data.filter(a =>
           a.id !== u_id
       ))
       removeID(u_id)
    }
    
      const removeID = (url) => {
        const index = user_id.indexOf(url);
        if (index > -1) { // only splice array when item is found
          user_id.splice(index, 1); // 2nd parameter means remove one item only
    
        }
        setUserId(user_id)
    
      }
    
      
      const checkedState=(id)=>{
        // const index = user_id.indexOf(id);
        return user_id.find((element) => {
          return element === id;
        })
        // if (index > -1) { // only splice array when item is found
        //  return true;
        // }
        //  return false;
      }

      const _schdeule_done=()=>{
        window.location.href = "/meeting";
    }

      const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);
        var copyText = document.getElementsByClassName("myInput");
        var data = {
          'user_id': user_id,
          'room_id': props.meetingRoom?.room_name,
          'message': copyText[0].textContent+'\n\n'+copyText[1].textContent+'\n'+copyText[2].textContent+'\n'+copyText[3].textContent+'\n\n'+copyText[4].textContent+'\n'+copyText[5].textContent+'\n'+copyText[6].textContent,
          'message_type': 10,
        }
        API.post('meeting_share', data).then(response => {
          setLoading(false);
          if (response.data.success === true) {
              toast.success('Meeting share successfully');
              props.handleShowB()
            // _schdeule_done()
          }
        }).catch((error) => {
          setLoading(false);
          console.log(error.message)
          toast.error('Please try again.');
        });
      }

      const _start_meeting=(meeting_id)=>{
        if(meeting_id){
          window.location.href = "/meeting/"+meeting_id;
        }else{
          toast.error("Please enter meeting id");
        }
      }

    return (<>
        <Modal
            isOpen={props.isModalVisible}
            size=""
            toggle={props.handleShowB}
            className={'modal-dialog-scrollable popup-max-height'} backdrop="static"
        >
          
            <ModalBody className="bg-light">
                <h4 style={{letterSpacing:'.03em',fontSize:'20px',color:'#131619',lineHeight:'32px'}}>Copy Meeting Invitation</h4>
             
                <div className="meeting-schedule-popup">
                <form onSubmit={handleSubmit}>
              <div className="meeting-invite-popup">

                <div className="form-control" style={{border:'2px solid #ccc',letterSpacing:'.03em',color:'#131619'}}>
                <p className="m-0 p-0 text-dark myInput">{myArr?.name} is inviting you to a scheduled connects meeting.</p>
                <br/>
                <p className="m-0 p-0 text-dark myInput">Topic: {props.meetingRoom?.room_title}</p>
                <p className="m-0 p-0 text-dark myInput">Time: {new Date(props.meetingRoom?.start_date).toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12: true })}</p>
                <p className="m-0 p-0 text-dark myInput">Timezone: {props.meetingRoom?.time_zone}</p>
                <br/>
                <p className="m-0 p-0 text-dark myInput">Join Connect Meeting</p>
                <p className="m-0 p-0 text-dark myInput">https://connectsapp.com/meeting/{props.meetingRoom?.room_name}</p>
                <p className="m-0 p-0 text-dark myInput">Meeting ID: {props.meetingRoom?.room_name}</p>
                </div>

                <div className="row mb-3">
                  <div className="col-12">
                    <label htmlFor="i_Friends" className="form-label form-label-font-14">Invite Contact</label>
                    <div className="dropdown w-100" id="i_Friends">
                      <input onChange={(e) => search_friend(e.target.value)} className="btn bg-white  text-secondary text-start invite_f_dropdown dropdown-toggle w-100" aria-expanded="false" placeholder="Invite Friends"  />

                      {name!=='' && data?.length > 0 && isLoading ?
                          <div className="search-item shadow-sm" id="search-item" style={{display: 'block',height:'auto'}}>
                            <div className="search-list">
                              <div className="recent-chat">
                                <ul className="list-group list-group-flush">


                                  {data?.map((value, index) => (

                                      <li className="list-group-item " key={index}>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="reaction-pp d-flex align-items-center">
                                            <div className="recent-chat-user-img me-2">
                                              <a href={`/contact/${value.user_id}`}>
                                                <div className="user-picture-container rounded-circle"
                                                     style={{background: `url("${value.avatar}")`}}/>
                                              </a>
                                            </div>
                                            <div className="name">
                                              <a href={`/contact/${value.user_id}`}
                                                 className="text-dark">{value.name}</a>
                                              <p style={{fontSize: '12px'}}>{value.email}</p>
                                            </div>
                                          </div>

                                          <div className="reaction-btn">

                                            <input defaultChecked={checkedState(value.user_id)} name={`user[${value.user_id}]`} className="form-check-input"
                                                   title={value.name} value={value.user_id} type="checkbox"
                                                    onClick={(e) => AddUserId(e)}
                                                   id={`custom-checkbox-${index}`}/>

                                          </div>
                                        </div>
                                      </li>
                                  ))}

                                </ul>
                              </div>
                            </div>
                          </div>
                          : ''
                      }



                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  {user_select_data?.length>0?
                  
                  user_select_data.map((username, i) =>
                  <>
                  <span key={i} className="badge bg-white shadow-sm rounded-pill text-dark">{username['name']} <i className="me-1 fas fa-times-circle" onClick={()=>removeUser(i,username['id'])} /></span>
                  </>
                  )
                  :''
                }
                  </div>
                  </div>
                  <ModalFooter className={'post-footer-comment-icon'}>
                <button type="button" className="btn btn-primary " onClick={myFunction}>Copy Meeting Invitation</button>
                <button type="submit" className="btn btn-success" style={{backgroundColor:'green'}}>Share Meeting</button>
                <button type="button" className="btn btn-danger" style={{backgroundColor:'red'}} onClick={props.handleShowB}>Cancel</button>
            </ModalFooter>
            </form>
          </div>
            </ModalBody>
          
        </Modal>
    </>)
}