import { combineReducers } from 'redux';
import homeReducer from './homeReducer';
import userReducer from './userReducer';
import notifyReducer from './notifyReducer';
import messageReducer from './messageReducer';
import userInboxReducer from './userInboxReducer';
import inboxAttachment from './inboxAttachment';
import postReducer from './postReducer';
import friendProfileReducer from './friendProfileReducer';
import ChatListId from './ChatListId';
import groupMemberSlice from './groupMemberReducer';
import roleSlice from './memberRoleReducer';
import UserSelfReducer from './UserSelfReducer';
import singlePostReducer from './singlePostReducer';
export default combineReducers({
    homeReducer:homeReducer,
    userReducer:userReducer,
    notifyReducer:notifyReducer,
    messageReducer:messageReducer,
    chatLogs:userInboxReducer,
    inboxAttachment:inboxAttachment,
    postReducer:postReducer,
    friendProfileReducer:friendProfileReducer,
    ChatListId:ChatListId,
    members:groupMemberSlice,
    role:roleSlice,
    userSelf:UserSelfReducer,
    singlePostReducer:singlePostReducer,
});