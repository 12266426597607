/* eslint-disable */

import Nav from "../Partials/Nav";
import LeftSide from "../Partials/LeftSide";
import RightSide from "../Partials/RightSide";
import MobileNav from "../Partials/MobileNav";
import Load from "../Load";
import React, {Component, Fragment, useState, useEffect} from "react";
import API from "../../Network/API";
import { withRouter } from "react-router";
import {useNavigate, BrowserRouter as Router, Redirect, Switch, Route, Routes, Link, useParams} from "react-router-dom";
import {toast} from 'react-toastify';


toast.configure();

export default function MeetingRoom() {
    let { room } = useParams();
    console.log(room);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState("");

    const containerStyle = {
        width: '100%',
        height: '100%',
    };

    const meetingContainerStyle = {
        //   display: (loading ? 'none' : 'block'),
          width: '100%',
          height: '100vh',
        }

    function startConference(_token, room) {

        try {
        const domain = 'eu-west-21.connectsweb.com';
        const options = {
            roomName: room,
            jwt: _token,
            height: '100%',
            parentNode: document.getElementById('meeting-container'),
            interfaceConfigOverwrite: {
            filmStripOnly: false,
            SHOW_JITSI_WATERMARK: false,
            },
            configOverwrite: {
            disableSimulcast: false,
            },
        };
        
        const api = new JitsiMeetExternalAPI(domain, options);
        
        api.addEventListener(
            'videoConferenceLeft', () => {
            console.log('videoConferenceLeft');
            navigate('/');
            },
            'videoConferenceJoined', () => {
            console.log('Local User Joined');
            }
        );
        api.getAvailableDevices().then(devices => {
            // devices
            setLoading(false)
        });
    
        } catch (error) {
        console.error('Failed to load meeting API', error);
        }
        }
    
    // custom events
    function executeCommand(command) {
        api.executeCommand(command);;
        if(command == 'hangup') {
            navigate('/');
        }
    }
    
    const handleClose = () => {
        console.log("handleClose");
    }

    function check_meeting() {
        API.get(`/join/`+room).then(response => {
            if (response.data.room!==""){
                setToken(response.data.jwt);
              startConference(response.data.jwt, room);
            }
            if (response.data.error_code==="406"){
                window.location.href = "/meeting-waiting/"+room;
            }
            if (response.data.error_code==='404'){
                toast.error('Invalid meeting id', {
                    position: "bottom-left",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: () => window.location.href = "/meeting"
                });
            }
            
        }).catch((err) => {
            toast.error('Invalid meeting', {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                onClose: () => window.location.href = "/meeting"
            });


        });
    }

    useEffect(() => {
        setTimeout(()=>{
            check_meeting()
        }, 3000);
    }, []);

    localStorage.setItem("set_meeting_url", null);
    if (localStorage.getItem("connect_token") === 'undefined' || localStorage.getItem("connect_token") == null) {
        localStorage.setItem("set_meeting_url", window.location.href);
    }
    return (
        <Fragment>
            {/* <div className="main-content-wrapper d-flex flex-column"> */}
                {/* <Nav/> */}
                <div className="content-page-box-area">
                    {/* <div className="container-fluid"> */}
                        <div className="row">
                            <div style={containerStyle}>
                                {loading ? <Load /> : ""}
                                <div id="meeting-container" style={meetingContainerStyle} />
                            </div> 
                        </div>
                    {/* </div> */}
                </div>
            {/* </div> */}

            <div className="mobile-bottom-nav d-xl-none fixed-bottom shadow-lg mobile-menu">
                {/* <MobileNav/> */}
            </div>

            </Fragment>
    )
}