import React, {Fragment, useEffect} from "react";
import Nav from "../Partials/Nav";
import MobileNav from "../Partials/MobileNav";
import {Outlet} from "react-router-dom";
import { useDispatch } from "react-redux";
import Agent from "../../Network/Agent";


export default function MainSectionMessages() {
    const dispatch = useDispatch();
    useEffect(() => {
        
        Agent.Notification.latest(dispatch)
            Agent.Message.chatList(dispatch)
        }, []);
    return(
        <Fragment>
            <div>
                <div className="main-content-wrapper d-flex flex-column">
                    <Nav/>
                    <div className="content-page-box-area">
                        <div className="container-fluid">
                            <Outlet/>
                        </div>
                    </div>
                </div>

                <div className="mobile-bottom-nav d-xl-none fixed-bottom shadow-lg mobile-menu">
                    <MobileNav/>
                </div>
            </div>

        </Fragment>
    )
}