import { createSlice } from '@reduxjs/toolkit';

export const groupMemberSlice = createSlice({
    name:'group_members',
    initialState:{
        loading: false,
        data: [],
        error: false,
    },
    reducers:{
        MEMBER_FETCH_START:(state)=>{
            state.loading=true
        },
        MEMBER_FETCH_SUCCESS:(state,action)=>{
            //  console.log(action.payload)
            state.loading=false
            state.data=action.payload
        },
        MEMBER_FETCH_REMOVE:(state,action)=>{
            const filterPosts = state.data.filter(
                (user) => user.id !== action.payload
              );
              state.loading = false
            state.data=filterPosts
        },
        MEMBER_FETCH_ERROR:(state)=>{
            state.error=true
        }
    }
});

export const { MEMBER_FETCH_START,MEMBER_FETCH_ERROR,MEMBER_FETCH_REMOVE,MEMBER_FETCH_SUCCESS }=groupMemberSlice.actions;
export default groupMemberSlice.reducer;